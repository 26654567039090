import {
  createListenerMiddleware,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'

import { AppState } from '@store/store'
import { appActions } from '@store/slices/app.slice'
import {
  LAST_WATCHED_CATCHUP_PROGRAM_DATA,
  RECENTLY_VIEWED_EPISODES,
} from '@utils/vars'
import {
  IAddChannelsToExtraCategoryPayload,
  ILastWatchedCatchupProgramData,
} from '@assets/types/player.types'
import { moviesActions } from '@store/slices/movies/movies.slice'
import { playerActions } from '@store/slices/player.slice'

export const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: isAnyOf(appActions.app_setLastWatchedCatchupProgram),
  effect: (_, listenerApi) => {
    const appState = listenerApi.getState() as AppState
    const currentProgramIdx =
      appState.player.hlsPlayer.currentChannelEpg.currentProgramIdx
    const items = appState.player.hlsPlayer.currentChannelEpg.items
    const selectedCategoryIdx = appState.player.categories.selectedCategoryIdx
    const categories = appState.player.categories.items
    const selectedChannelIdx = appState.player.channels.selectedChannelIdx
    const timeshiftValue = appState.player.hlsPlayer.timeshift.timeshiftValue
    const lastWatchedCatchupProgramData: ILastWatchedCatchupProgramData = {
      channelLogo:
        categories[selectedCategoryIdx].channels[selectedChannelIdx].logo,
      channelId:
        categories[selectedCategoryIdx].channels[selectedChannelIdx].id,
      channelNames:
        categories[selectedCategoryIdx].channels[selectedChannelIdx].names,
      currentTime: timeshiftValue,
      startAt: items[currentProgramIdx].start_at,
      stopAt: items[currentProgramIdx].stop_at,
      programName: items[currentProgramIdx].title,
    }

    localStorage.setItem(
      LAST_WATCHED_CATCHUP_PROGRAM_DATA,
      JSON.stringify(lastWatchedCatchupProgramData)
    )
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(
    moviesActions.movies_loadRecentlyViewedEpisodesFromLocalStorage
  ),
  effect: (_, listenerApi) => {
    const appState = listenerApi.getState() as AppState
    const recentlyViewedEpisodes = appState.movies.recentlyViewedEpisodes
    if (recentlyViewedEpisodes?.length) {
      localStorage.setItem(
        RECENTLY_VIEWED_EPISODES,
        JSON.stringify(recentlyViewedEpisodes)
      )
    } else {
      localStorage.removeItem(RECENTLY_VIEWED_EPISODES)
    }
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(
    playerActions.player_categories_setChannelItemsToExtraChannelsCategory
  ),
  effect: (
    action: PayloadAction<IAddChannelsToExtraCategoryPayload>,
    listenerApi
  ) => {
    const appState = listenerApi.getState() as AppState
    const category = appState.player.categories.items.find(
      (category) => category.id === action.payload.targetCategoryId
    )
    
    if (!category?.channels.length)
      return localStorage.removeItem(action.payload.localStorageItemName)

    const channelsIds: string[] = []

    category?.channels.forEach((channel) =>
      channelsIds.push(channel.id.toString())
    )

    localStorage.setItem(
      action.payload.localStorageItemName,
      JSON.stringify(channelsIds)
    )
  },
})
