import React, { FC } from 'react'
import KeyboardEventHandler from '@infinium/react-keyboard-event-handler'

import { useTypedAction } from '@hooks/common/useTypedActions'

import {
  KEY_ENTER,
  KEY_HIDE_CURSOUR_WEBOS,
  KEY_BACK_WEBOS,
  KEY_BACK_TIZEN,
  KEY_BACKSPACE
} from '@utils/keyCodes'
import { removeHoverEffect } from '@utils/removeHoverEffect'

export const InfoDialogActions: FC = () => {
  const { dialogs_info_resetInfoDialogState } = useTypedAction()
  return (
    <KeyboardEventHandler
      handleKeys={['all']}
      isExclusive
      handleFocusableElements
      onKeyEvent={(_, event) => {
        const { keyCode } = event
        switch (keyCode) {
        case KEY_ENTER: {
          dialogs_info_resetInfoDialogState()
          break
        }
        case KEY_BACK_WEBOS: {
          dialogs_info_resetInfoDialogState()
          break
        }
        case KEY_BACK_TIZEN: {
          dialogs_info_resetInfoDialogState()
          break
        }
        case KEY_BACKSPACE: {
          dialogs_info_resetInfoDialogState()
          break
        }
        case KEY_HIDE_CURSOUR_WEBOS: {
          removeHoverEffect()
          break
        }
        default:
          return
        }
      }}
    />
  )
}
