import React, { FC } from 'react'

import { useTypedAction } from '@hooks/common/useTypedActions'
import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { Button } from '@components/UIElements/Button/Button'
import { Message } from '@components/UIElements/Dialogs/Message/Message'

import styles from '@components/Dialogs/Common/InfoDialog/info.dialog.module.scss'

interface IInfoDialogBodyProps {
  action?: () => void
  text?: string | null
}

export const InfoDialogBody: FC<IInfoDialogBodyProps> = ({ action, text }) => {
  const message = useTypedSelector((state) => state.dialogs.info.message)

  const { dialogs_info_resetInfoDialogState } = useTypedAction()

  const description = text ? text : message

  return (
    <div className={styles.infoDialog}>
      {description && <Message description={description} />}
      <Button
        isActive
        className={styles.infoDialogBtn}
        onClick={action ? action : () => dialogs_info_resetInfoDialogState()}
      >
        ОК
      </Button>
    </div>
  )
}
