import { MouseEvent, useCallback } from 'react'

import btnStyles from '@components/UIElements/Button/button.module.scss'

export const mouseEnterElement = (
  element: HTMLElement,
  hoverClass: string,
  callBack?: () => void
) => {
  element.setAttribute('data-hover', hoverClass)
  element.classList.add(hoverClass)
  const items = element.querySelectorAll('[data-animation]')

  if (items.length) {
    // use only for (not forEach es6) WebOS bug
    for (let i = 0; i < items.length; i++) {
      const animation = items[i].getAttribute('data-animation')
      if (animation) {
        items[i].setAttribute('style', `animation: ${animation}`)
      }
    }
  }
  if (callBack) callBack()
}

export const memoizedMouseEnterElement = <T extends HTMLElement>(
  hoverClass: string | null,
  callBack?: () => void
) => {
  return useCallback((event: MouseEvent<T>) => {
    mouseEnterElement(
      event.currentTarget,
      hoverClass ? hoverClass : btnStyles.svgBtnHover,
      callBack
    )
  }, [])
}
