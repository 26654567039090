import React, { FC, PropsWithChildren } from 'react'
import { clsx } from 'clsx'

import appStyles from '@styles/app.module.scss'

interface IAppWrapperProps extends PropsWithChildren {
  className?: string
}

export const AppWrapper: FC<IAppWrapperProps> = ({ children, className }) => {
  return (
    <section
      className={clsx(
        className && className,
        __PLATFORM__ === 'desktop' ? appStyles.wrapper : appStyles.tvWrapper
      )}
    >
      {children}
    </section>
  )
}
