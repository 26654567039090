import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { CheckPassword } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'check-password'*/ '@components/Overlays/ParentalControl/CheckPassword/CheckPassword'
    ),
  'CheckPassword'
)

export const LazyCheckPassword: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.overlays.checkPassword.isOpened
  )

  return (
    <>
      {isOpened && (
        <Suspense fallback={null}>
          <CheckPassword />
        </Suspense>
      )}
    </>
  )
}
