import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IRecentlyViewedInitialState } from '@assets/types/movies/movies.types'
import { IFilm } from '@assets/types/movies/filmlist.types'

const initialState: IRecentlyViewedInitialState = {
  isActive: false,
  activeFilmIdx: 0,
  items: null,
}

export const recentlyViewedSlice = createSlice({
  name: 'recentlyViewed',
  initialState,
  reducers: {
    movies_recentlyViewed_reset: () => initialState,
    movies_recentlyViewed_setRecentlyViewedIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isActive = action.payload
    },
    movies_recentlyViewed_incrementActiveFilmIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.activeFilmIdx = state.activeFilmIdx + action.payload
    },
    movies_recentlyViewed_decrementActiveFilmIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.activeFilmIdx = state.activeFilmIdx - action.payload
    },
    movies_recentlyViewed_setActiveFilmIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.activeFilmIdx = action.payload
    },
    movies_recentlyViewed_setItems: (
      state,
      action: PayloadAction<null | IFilm[]>
    ) => {
      state.items = action.payload
    },
  },
})

export const recentlyViewedReducer = recentlyViewedSlice.reducer
export const recentlyViewedActions = recentlyViewedSlice.actions
