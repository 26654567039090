import React, { FC, memo } from 'react'

import styles from '@components/UIElements/Dialogs/Header/header.module.scss'

interface IHeaderProps {
  icon: string
  text: string
}

const HeaderComponent: FC<IHeaderProps> = ({ icon, text }) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerIconWrapper}>
        <img src={icon} className={styles.headerIcon} />
      </div>
      <span className={styles.headerText}>{text}</span>
    </div>
  )
}

export const Header = memo(HeaderComponent)