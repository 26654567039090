import { selectChannelFromTargetCategory } from '@store/selectors/channels'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

export const useChannelExistsInExtraCategory = (
  targetCategoryId: number,
  targetChannelId?: number
): boolean | undefined => {
  return !!useTypedSelector((state) =>
    selectChannelFromTargetCategory(state, {
      categoryId: targetCategoryId,
      channelId: targetChannelId,
    })
  )
}
