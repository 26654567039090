import React, { FC } from 'react'
import { clsx } from 'clsx'

import styles from './loader.module.scss'

interface ILoaderProps {
  size: number
  fullScreen?: boolean
  className?: string
}

export const Loader: FC<ILoaderProps> = ({ size, fullScreen, className }) => {
  return (
    <div
      className={clsx(styles.loader, fullScreen && styles.loaderFullscreen)}
      style={{ fontSize: `${size}em`, }}
    >
      <span className={clsx(!className ? styles.loaderDotDefault : className)} />
      <span className={clsx(!className ? styles.loaderDotDefault : className)} />
      <span className={clsx(!className ? styles.loaderDotDefault : className)} />
      <span className={clsx(!className ? styles.loaderDotDefault : className)} />
      <span className={clsx(!className ? styles.loaderDotDefault : className)} />
    </div>
  )
}
