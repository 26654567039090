import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useTypedAction } from '@hooks/common/useTypedActions'
import { useTypedSelector } from '@hooks/common/useTypedSelector'

import {
  authApi,
  useGet_middleware_auth_dataMutation,
} from '@store/slices/auth.slice'
import {
  playerApi,
  useLazyGet_categoriesQuery,
} from '@store/slices/player.slice'
import { useExitFromApp } from '@hooks/app/useCloseApp'

interface ICommonErrorData {
  isReconnect: boolean
  submitButtonName: string
  cancelButtonName?: string
  cancelHandler?: () => void
}

interface IErrorDialogData extends ICommonErrorData {
  errorDescription: string
  submitHandler: () => void
}

export const useErrorHandler = (): IErrorDialogData => {
  const dispatch = useDispatch()
  const [getCategories] = useLazyGet_categoriesQuery()
  const [getAuthData] = useGet_middleware_auth_dataMutation({
    fixedCacheKey: 'shared-auth',
  })

  const { t } = useTranslation(['errors', 'common', 'auth'])
  const playlist = useTypedSelector(
    (state) => state.auth.authSuccessData?.playlist
  )
  const code = useTypedSelector((state) => state.dialogs.error.code)
  const trashedType = useTypedSelector(
    (state) => state.auth.authErrorData?.trashed?.type
  )
  const attempts = useTypedSelector((state) => state.dialogs.reconnect.attempts)
  const reconnectErrorCode = useTypedSelector(
    (state) => state.dialogs.reconnect.reconnectErrorCode
  )
  const isAuthenticated = useTypedSelector(
    (state) => state.auth.isAuthenticated
  )

  const {
    dialogs_reconnect_setReconnectDialogIsOpened,
    dialogs_error_setErrorDialogIsOpened,
    dialogs_reconnect_resetReconnectState,
    dialogs_reconnect_setReconnectErrorCode,
    dialogs_error_resetErrorDialogState,
  } = useTypedAction()

  const retryHandler = () => {
    dispatch(authApi.util.resetApiState())
    dispatch(playerApi.util.resetApiState())
    dialogs_error_resetErrorDialogState()
    getAuthData()
  }

  const watchPromoHandler = () => {
    dialogs_error_resetErrorDialogState()
    if (playlist) getCategories({ playlist_hash: playlist, promo: true })
  }

  const commonErrorData: ICommonErrorData = {
    isReconnect: false,
    submitButtonName: t('common:retry'),
  }

  const wrongCodeErrorData = {
    ...commonErrorData,
    errorDescription: t('errors:auth.4003-13-11-92-error'),
    submitHandler: () => dialogs_error_resetErrorDialogState(),
    submitButtonName: t('common:aware'),
  }

  const maxDevicesErrorData = {
    ...commonErrorData,
    errorDescription: t('errors:auth.1704-20-error'),
    submitHandler: retryHandler,
    cancelButtonName: t('common:exit'),
    cancelHandler: useExitFromApp,
  }

  const tooManyAttemptsErrorData = {
    ...commonErrorData,
    errorDescription: t('errors:auth.4001-12-error'),
    submitButtonName: t('common:aware'),
    submitHandler: () => dialogs_error_resetErrorDialogState(),
  }

  const errorsData: Record<string, IErrorDialogData> = useMemo(
    () =>
      ({
        // middleware errors
        1: {
          ...commonErrorData,
          errorDescription: t('errors:auth.1-error'),
          submitHandler: watchPromoHandler,
          submitButtonName: t('auth:watch-promo'),
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        2: {
          ...commonErrorData,
          errorDescription: t('errors:auth.2-error'),
          submitHandler: retryHandler,
          isReconnect: true,
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        3: {
          ...commonErrorData,
          errorDescription: t('errors:auth.3-error'),
          submitHandler: retryHandler,
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        5: {
          ...commonErrorData,
          errorDescription: t('errors:auth.5-error'),
          submitHandler: retryHandler,
          isReconnect: true,
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        7: {
          ...commonErrorData,
          errorDescription: t('errors:auth.7-error'),
          submitHandler: retryHandler,
          isReconnect: true,
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        8: {
          ...commonErrorData,
          errorDescription: t('errors:auth.8-error'),
          submitHandler: retryHandler,
          isReconnect: true,
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        9: {
          ...commonErrorData,
          errorDescription: t('errors:auth.9-error'),
          submitHandler: retryHandler,
          isReconnect: true,
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        10: {
          ...commonErrorData,
          errorDescription: t('errors:auth.10-error'),
          submitButtonName: t('auth:change-phone'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          cancelButtonName: t('common:exit'),
          cancelHandler: useExitFromApp,
        },
        11: wrongCodeErrorData,
        12: tooManyAttemptsErrorData,
        13: wrongCodeErrorData,
        20: maxDevicesErrorData,
        92: wrongCodeErrorData,

        // api.omegatv.ua errors
        1704: maxDevicesErrorData,
        4000: {
          ...commonErrorData,
          errorDescription: t('errors:auth.4000-error'),
          submitHandler: useExitFromApp,
          submitButtonName: t('common:exit'),
        },
        4001: tooManyAttemptsErrorData,
        4003: wrongCodeErrorData,
        4004: {
          ...commonErrorData,
          errorDescription: t('errors:auth.4004-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4006: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4006-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4007: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4007-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4008: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4008-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4009: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4009-4046-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4011: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4011-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4046: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4009-4046-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4064: {
          ...commonErrorData,
          errorDescription: t('errors:activate-voucher.4064-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4072: {
          ...commonErrorData,
          errorDescription: t('errors:auth.4072-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        4080: {
          ...commonErrorData,
          errorDescription: t('errors:auth.4080-error'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('auth:change-phone'),
        },
        2330: {
          ...commonErrorData,
          errorDescription: t('errors:auth.2330-error'),
          submitHandler: retryHandler,
          submitButtonName: t('common:retry'),
          cancelHandler:
            __PLATFORM__ !== 'desktop' ? useExitFromApp : undefined,
        },

        // react-api errors
        7000: {
          ...commonErrorData,
          errorDescription: t('errors:payment.7000-generate-qr'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },
        7001: {
          ...commonErrorData,
          errorDescription: t('errors:payment.7001-get-qr'),
          submitHandler: () => dialogs_error_resetErrorDialogState(),
          submitButtonName: t('common:aware'),
        },

        // error unknown device
        7099: {
          ...commonErrorData,
          errorDescription: t('errors:auth.7099-error'),
          submitHandler: useExitFromApp,
          submitButtonName: t('common:exit'),
        },
        7100: {
          ...commonErrorData,
          errorDescription: 'WRONGSTBDATAUNIQ',
          submitHandler: retryHandler,
          submitButtonName: t('common:retry'),
          cancelHandler:
            __PLATFORM__ !== 'desktop' ? useExitFromApp : undefined,
        },

        default: {
          ...commonErrorData,
          errorDescription: t('errors:auth.other-error'),
          submitHandler: !isAuthenticated
            ? retryHandler
            : () => dialogs_error_resetErrorDialogState(),
          submitButtonName: !isAuthenticated
            ? t('common:retry')
            : t('common:aware'),
          cancelButtonName: !isAuthenticated ? t('common:exit') : undefined,
          cancelHandler: !isAuthenticated ? useExitFromApp : undefined,
        },
      } as const),
    [isAuthenticated, playlist]
  )

  useEffect(() => {
    if (code) {
      // Suspend screen error
      if (code === '17') return
      if (code === '1' && trashedType === 'paused') return

      // if we get unknown error (show default case error)
      if (!errorsData[code]) {
        dialogs_error_setErrorDialogIsOpened(true)
        return
      }

      if (errorsData[code].isReconnect) {
        // Show error dialog after 5 attempts reconnect
        if (attempts === 5) {
          dialogs_reconnect_resetReconnectState()
          dialogs_error_setErrorDialogIsOpened(true)
          return
        }

        // Reset state reconnect if we get another error
        if (reconnectErrorCode !== code) {
          dialogs_reconnect_setReconnectErrorCode(code)
          if (reconnectErrorCode) dialogs_reconnect_resetReconnectState()
        }
        dialogs_reconnect_setReconnectDialogIsOpened(true)
      } else {
        dialogs_error_setErrorDialogIsOpened(true)
        if (attempts !== 0) dialogs_reconnect_resetReconnectState()
      }
    }
  }, [code])

  return errorsData[code ?? ''] ?? errorsData.default
}
