export const removeHoverEffect = () => {
  const element = document.querySelector('[data-hover]')
  const hoverClass = element?.getAttribute('data-hover')
  if (element) {
    const items = element.querySelectorAll('[data-animation]')
    
    if (items.length) {
      // use only for (not forEach es6) WebOS bug
      for (let i = 0; i < items.length; i++) {
        const isActive = !!items[i].getAttribute('data-active')
        if (isActive) return
        const animation = items[i].getAttribute('data-animation')
        if (animation) {
          items[i].removeAttribute('style')
        }
      }
    }
    element.removeAttribute('data-hover')
    if (hoverClass) element?.classList.remove(hoverClass)
  }
}
