import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { SetPassword } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'set-password'*/ '@components/Overlays/ParentalControl/SetPassword/SetPassword'
    ),
  'SetPassword'
)

export const LazySetPassword: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.overlays.setPassword.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <SetPassword />}</Suspense>
}
