import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'

import {
  IFilm,
  IGetFilmsRequest,
  IGetFilmsResponse,
  IMoviesFilmListInitialState,
} from '@assets/types/movies/filmlist.types'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { dialogsActions } from '@store/slices/dialogs.slice'

const initialState: IMoviesFilmListInitialState = {
  skipFetchItems: false,
  isActive: false,
  page: 1,
  showLoader: false,
  localpage: 1,
  totalLocalPages: 0,
  hasMore: true,
  isFetching: true,
  isFiltering: true,
  tempFilmsArray: [],
  films: [],
  activeFilmIdx: 0,
}

export const moviesFilmListSlice = createSlice({
  name: 'moviesFilmList',
  initialState,
  reducers: {
    movies_filmList_filmListReset: () => initialState,
    movies_filmList_setFilmListIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isActive = action.payload
    },
    movies_filmList_setFetchedFilms: (
      state,
      action: PayloadAction<IFilm[]>
    ) => {
      state.tempFilmsArray = [...state.tempFilmsArray, ...action.payload]
    },
    movies_filmList_setFilteredFilms: (state) => {
      const arrlenght = 30
      for (let i = 0; i < state.tempFilmsArray.length; i += arrlenght) {
        const chunk = state.tempFilmsArray.slice(i, i + arrlenght)
        if (state.tempFilmsArray.length >= arrlenght && chunk.length === 30) {
          state.films.push(chunk)
          state.tempFilmsArray.splice(0, arrlenght)
        }
      }
      state.totalLocalPages = state.films.length
      state.isFiltering = false
    },
    movies_filmList_moveRestItems: (state) => {
      if (state.tempFilmsArray.length) {
        state.films.push([...state.tempFilmsArray])
      }
      state.totalLocalPages = state.films.length
      state.tempFilmsArray = []
    },
    movies_filmList_incrementActiveFilm: (
      state,
      action: PayloadAction<number>
    ) => {
      state.activeFilmIdx = state.activeFilmIdx + action.payload
    },
    movies_filmList_decrementActiveFilm: (
      state,
      action: PayloadAction<number>
    ) => {
      state.activeFilmIdx = state.activeFilmIdx - action.payload
    },
    movies_filmList_setActiveFilm: (state, action: PayloadAction<number>) => {
      state.activeFilmIdx = action.payload
    },
    movies_filmList_incrementPage: (state) => {
      state.isFetching = true
      state.isFiltering = true
      state.page++
    },
    movies_filmList_incrementLocalPage: (state) => {
      state.localpage++
    },
    movies_filmList_decrementLocalPage: (state) => {
      state.localpage--
    },
    movies_filmList_setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload
    },
    movies_filmList_setIsFiltering: (state, action: PayloadAction<boolean>) => {
      state.isFiltering = action.payload
    },
    movies_filmList_setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    movies_filmList_setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload
    },
    movies_filmList_setSkipFetchItems: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.skipFetchItems = action.payload
    },
  },
})

export const moviesFilmListApi = createApi({
  reducerPath: 'moviesFilmListApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  endpoints: (builder) => ({
    getFilms: builder.query<IGetFilmsResponse, IGetFilmsRequest>({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_CDN_URL}/v1/catalog/${_args.category}/${_args.page}/list.json?lang=${_args.language}`
        try {
          const { data } = await axios.get<IGetFilmsResponse>(url)
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
})
export const moviesFilmListReducer = moviesFilmListSlice.reducer
export const moviesFilmListActions = moviesFilmListSlice.actions

export const { useGetFilmsQuery } = moviesFilmListApi
