import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ConfirmReplenish } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'confirm-replenish'*/ '@components/Dialogs/ConfirmReplenish/ConfirmReplenish'
    ),
  'ConfirmReplenish'
)

export const LazyConfirmReplenish: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.replenish.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <ConfirmReplenish />}</Suspense>
}
