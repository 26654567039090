import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import md5 from 'md5'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { t } from 'i18next'
import {
  IAddChannelsToExtraCategoryPayload,
  IChannel,
  IChannelsCategoriesResponse,
  IGetEpgRangeRequest,
  IGetEpgRangeResponse,
  IGetTimeshiftUrlRequest,
  IGetTimeshiftUrlResponse,
  IPlayerInitialState,
  TControlsMainPosition,
  TRewindType,
} from '@assets/types/player.types'
import {
  GetEpgByDayRequest,
  IGetEpgByDayResponse,
} from '@assets/types/common.types'

import { AppState } from '@store/store'
import { authActions, authApi } from '@store/slices/auth.slice'
import { appActions } from '@store/slices/app.slice'
import { moviesActions } from '@store/slices/movies/movies.slice'
import { overlaysActions } from '@store/slices/overlays.slice'
import {
  ALL_CHANNELS_CATEGORY_ID,
  BLOCKED,
  BLOCKED_CHANNELS_CATEGORY_ID,
  FAVOURITE,
  FAVOURITE_CHANNELS_CATEGORY_ID,
  LAST_WATCHED_CATEGORY_ID,
  LAST_WATCHED_CHANNEL_ID,
  PARENTAL_CONTROL,
} from '@utils/vars'

import { useGetItemFromLocalStorage } from '@hooks/app/localStorage/useGetItemFromLocalStorage'
import { useGetParsedItemFromLocalStorage } from '@hooks/app/localStorage/useGetParsedItemFromLocalStorage'

import { dialogsActions } from '@store/slices/dialogs.slice'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { changeCdnDomain } from '@utils/changeCdnDomain'

const initialState: IPlayerInitialState = {
  hlsPlayer: {
    isAutoPlay: __PLATFORM__ === 'desktop' ? false : true,
    isLive: true,
    isCatchup: false,
    isPlaying: true,
    isRewind: false,
    rewindType: 'backward',
    isActive: false,
    src: null,
    custom_cdn: null,
    timeshift: {
      timeshiftValue: 0,
      lastWatchedTimestamp: null,
      timeshiftDebouncedValue: null,
      rewindValue: 0,
      rewindStep: 10,
      totalRewind: 0,
    },
    currentChannelEpg: {
      loadMore: false,
      isFetching: false,
      prevDay: -1,
      day: 0,
      currentProgramIdx: 0,
      items: [],
    },
  },
  channels: {
    activeChannelIdx: 0,
    selectedChannelIdx: 0,
    selectedChannelID: 0,
  },
  categories: {
    items: [],
    activeCategoryIdx: 0,
    selectedCategoryIdx: 0,
  },
  elements: {
    hideElementsValue: null,
    promo: {
      isActive: false,
    },
    header: {
      isShown: false,
      leftMenu: {
        isActive: false,
      },
      rightMenu: {
        isActive: false,
      },
    },
    footer: {
      isShown: false,
      playback: {
        isActive: false,
      },
      controls: {
        parentalControl: {
          isActive: false,
        },
        favorite: {
          isActive: false,
        },
        main: {
          isActive: false,
          position: 'PlayPause',
        },
        rightSide: {
          isActive: false,
          position: 'Settings',
        },
      },
    },
  },
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  // add custom cdn to player slice after get success auth data
  extraReducers: (builder) => {
    builder.addCase(
      authActions.auth_setSuccessMiddlewareAuthData,
      (state, action) => {
        if (action.payload.custom_cdn)
          state.hlsPlayer.custom_cdn = action.payload.custom_cdn
      }
    )
  },
  reducers: {
    // Hls player
    player_hlsPlayer_setIsActive: (state, action: PayloadAction<boolean>) => {
      state.hlsPlayer.isActive = action.payload
    },
    player_hlsPlayer_setIsLive: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        const timeNow = dayjs().unix()
        if (state.hlsPlayer.isRewind) state.hlsPlayer.isRewind = false

        const channelLink =
          state.categories.items[state.categories.selectedCategoryIdx].channels[
            state.channels.selectedChannelIdx
          ].link

        state.hlsPlayer.src = state.hlsPlayer.custom_cdn
          ? changeCdnDomain(channelLink, state.hlsPlayer.custom_cdn)
          : channelLink

        state.hlsPlayer.timeshift.timeshiftValue = timeNow
        state.hlsPlayer.timeshift.timeshiftDebouncedValue =
          initialState.hlsPlayer.timeshift.timeshiftDebouncedValue
        if (state.hlsPlayer.isCatchup) {
          state.hlsPlayer.isCatchup = false
          state.hlsPlayer.currentChannelEpg.items.forEach((program, index) => {
            if (program.start_at <= timeNow && program.stop_at >= timeNow) {
              state.hlsPlayer.currentChannelEpg.currentProgramIdx = index
            }
          })
        }
      }
      state.hlsPlayer.isLive = action.payload
      if (!state.hlsPlayer.isPlaying) state.hlsPlayer.isPlaying = true
    },
    player_hlsPlayer_toggleIsLive: (state, action: PayloadAction<boolean>) => {
      state.hlsPlayer.isLive = action.payload
    },
    player_hlsPlayer_setSrc: (state, action: PayloadAction<string | null>) => {
      if (state.hlsPlayer.custom_cdn && action.payload) {
        state.hlsPlayer.src = changeCdnDomain(
          action.payload,
          state.hlsPlayer.custom_cdn
        )
      } else {
        state.hlsPlayer.src = action.payload
      }
    },
    player_hlsPlayer_setIsAutoPlay: (state, action: PayloadAction<boolean>) => {
      state.hlsPlayer.isAutoPlay = action.payload
    },
    player_hlsPlayer_setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.hlsPlayer.isPlaying = action.payload
    },
    player_hlsPlayer_timeshift_setLastWatchedTimestamp: (
      state,
      action: PayloadAction<null | undefined>
    ) => {
      if (action.payload === undefined) {
        state.hlsPlayer.timeshift.lastWatchedTimestamp =
          state.hlsPlayer.timeshift.timeshiftValue
      } else {
        state.hlsPlayer.timeshift.lastWatchedTimestamp = null
      }
    },
    player_hlsPlayer_setIsRewind: (state, action: PayloadAction<boolean>) => {
      state.hlsPlayer.isRewind = action.payload
    },
    player_hlsPlayer_setRewindType: (
      state,
      action: PayloadAction<TRewindType>
    ) => {
      state.hlsPlayer.rewindType = action.payload
    },
    player_hlsPlayer_resetStateAfterSwitchChannel: (state) => {
      state.hlsPlayer.timeshift = initialState.hlsPlayer.timeshift
      state.hlsPlayer.currentChannelEpg =
        initialState.hlsPlayer.currentChannelEpg
      state.hlsPlayer.isCatchup = initialState.hlsPlayer.isCatchup
      state.hlsPlayer.isRewind = initialState.hlsPlayer.isRewind
      state.hlsPlayer.isLive = initialState.hlsPlayer.isLive
    },
    player_hlsPlayer_setIsCatchup: (state, action: PayloadAction<boolean>) => {
      state.hlsPlayer.isCatchup = action.payload
    },

    // Timeshift
    player_hlsPlayer_timeshift_counter: (state) => {
      state.hlsPlayer.timeshift.timeshiftValue =
        state.hlsPlayer.timeshift.timeshiftValue + 1
    },
    player_hlsPlayer_timeshift_setTimeshiftValue: (
      state,
      action: PayloadAction<number>
    ) => {
      state.hlsPlayer.timeshift.timeshiftValue = action.payload
    },
    player_hlsPlayer_timeshift_setTimeshiftDebouncedValue: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.hlsPlayer.timeshift.timeshiftDebouncedValue = action.payload
    },

    player_hlsPlayer_timeshift_rewindBackward: (state) => {
      // if current epg index === 0 and day !== -7 load more epg
      if (
        state.hlsPlayer.currentChannelEpg.day !== -7 &&
        state.hlsPlayer.currentChannelEpg.currentProgramIdx === 0 &&
        state.hlsPlayer.currentChannelEpg.items.length &&
        state.hlsPlayer.timeshift.timeshiftValue <=
          state.hlsPlayer.currentChannelEpg.items[
            state.hlsPlayer.currentChannelEpg.currentProgramIdx
          ].start_at
      ) {
        state.hlsPlayer.currentChannelEpg.prevDay--
        state.hlsPlayer.currentChannelEpg.day =
          state.hlsPlayer.currentChannelEpg.prevDay
        state.hlsPlayer.currentChannelEpg.loadMore = true
        return
      }

      // if timeshift value <= program start
      if (
        state.hlsPlayer.currentChannelEpg.items.length &&
        state.hlsPlayer.timeshift.timeshiftValue <=
          state.hlsPlayer.currentChannelEpg.items[
            state.hlsPlayer.currentChannelEpg.currentProgramIdx
          ].start_at
      ) {
        const storageTime =
          state.categories.items[state.categories.selectedCategoryIdx].channels[
            state.channels.selectedChannelIdx
          ].storage_time ?? +process.env.DEFAULT_STORAGE_TIME

        // check if prev program isCatchup by storage time channel
        if (
          dayjs().unix() -
            state.hlsPlayer.currentChannelEpg.items[
              state.hlsPlayer.currentChannelEpg.currentProgramIdx - 1
            ].start_at >=
          storageTime
        ) {
          return
        }
        if (!state.hlsPlayer.isCatchup) state.hlsPlayer.isCatchup = true
        state.hlsPlayer.currentChannelEpg.currentProgramIdx--
        state.hlsPlayer.timeshift.timeshiftValue =
          state.hlsPlayer.currentChannelEpg.items[
            state.hlsPlayer.currentChannelEpg.currentProgramIdx
          ]?.stop_at
        return
      }
      state.hlsPlayer.timeshift.timeshiftValue =
        state.hlsPlayer.timeshift.timeshiftValue -
        state.hlsPlayer.timeshift.rewindStep

      // Set rewind value for rewind label
      state.hlsPlayer.timeshift.rewindValue =
        state.hlsPlayer.timeshift.rewindValue -
        state.hlsPlayer.timeshift.rewindStep

      // Progressive rewind
      state.hlsPlayer.timeshift.totalRewind =
        state.hlsPlayer.timeshift.totalRewind +
        state.hlsPlayer.timeshift.rewindStep

      if (state.hlsPlayer.timeshift.totalRewind === 480) {
        state.hlsPlayer.timeshift.rewindStep = 20
      }
      if (state.hlsPlayer.timeshift.totalRewind === 920) {
        state.hlsPlayer.timeshift.rewindStep = 40
      }
      if (state.hlsPlayer.timeshift.totalRewind === 1400) {
        state.hlsPlayer.timeshift.rewindStep = 60
      }
    },
    player_hlsPlayer_timeshift_rewindForward: (state) => {
      // Set live mode
      if (state.hlsPlayer.timeshift.timeshiftValue >= dayjs().unix()) {
        state.hlsPlayer.isLive = true
        if (state.hlsPlayer.isRewind) state.hlsPlayer.isRewind = false
        if (!state.hlsPlayer.isPlaying) state.hlsPlayer.isPlaying = true

        const link =
          state.categories.items[state.categories.selectedCategoryIdx].channels[
            state.channels.selectedChannelIdx
          ].link

        state.hlsPlayer.src = state.hlsPlayer.custom_cdn
          ? changeCdnDomain(link, state.hlsPlayer.custom_cdn)
          : link
        state.hlsPlayer.timeshift.timeshiftValue = dayjs().unix()
        state.hlsPlayer.timeshift.timeshiftDebouncedValue =
          initialState.hlsPlayer.timeshift.timeshiftDebouncedValue
        return
      }
      if (
        state.hlsPlayer.currentChannelEpg.items.length &&
        state.hlsPlayer.timeshift.timeshiftValue >=
          state.hlsPlayer.currentChannelEpg.items[
            state.hlsPlayer.currentChannelEpg.currentProgramIdx
          ].stop_at
      ) {
        // check if next program is live
        if (
          state.hlsPlayer.currentChannelEpg.items[
            state.hlsPlayer.currentChannelEpg.currentProgramIdx + 1
          ].stop_at > dayjs().unix()
        ) {
          state.hlsPlayer.isCatchup = false
          state.hlsPlayer.currentChannelEpg.currentProgramIdx++
          return
        }
        state.hlsPlayer.currentChannelEpg.currentProgramIdx++
      }

      state.hlsPlayer.timeshift.timeshiftValue =
        state.hlsPlayer.timeshift.timeshiftValue +
        state.hlsPlayer.timeshift.rewindStep

      // Set rewind value for rewind label
      state.hlsPlayer.timeshift.rewindValue =
        state.hlsPlayer.timeshift.rewindValue +
        state.hlsPlayer.timeshift.rewindStep

      // Progressive rewind
      state.hlsPlayer.timeshift.totalRewind =
        state.hlsPlayer.timeshift.totalRewind +
        state.hlsPlayer.timeshift.rewindStep

      if (state.hlsPlayer.timeshift.totalRewind === 480) {
        state.hlsPlayer.timeshift.rewindStep = 20
      }
      if (state.hlsPlayer.timeshift.totalRewind === 920) {
        state.hlsPlayer.timeshift.rewindStep = 40
      }
      if (state.hlsPlayer.timeshift.totalRewind === 1400) {
        state.hlsPlayer.timeshift.rewindStep = 60
      }
    },
    player_hlsPlayer_setRewindValue: (state, action: PayloadAction<number>) => {
      state.hlsPlayer.timeshift.rewindValue = action.payload
    },
    player_hlsPlayer_resetRewind: (state) => {
      state.hlsPlayer.timeshift.rewindStep =
        initialState.hlsPlayer.timeshift.rewindStep
      state.hlsPlayer.timeshift.totalRewind =
        initialState.hlsPlayer.timeshift.totalRewind
      state.hlsPlayer.timeshift.rewindValue =
        initialState.hlsPlayer.timeshift.rewindValue
    },

    // currentChannelEpg
    player_hlsPlayer_currentChannelEpg_pushItems: (
      state,
      action: PayloadAction<IGetEpgByDayResponse[]>
    ) => {
      state.hlsPlayer.currentChannelEpg.items.push(...action.payload)
    },
    player_hlsPlayer_currentChannelEpg_unshifItems: (
      state,
      action: PayloadAction<{
        currentProgramIdx: number
        items: IGetEpgByDayResponse[]
      }>
    ) => {
      state.hlsPlayer.currentChannelEpg.isFetching = false
      state.hlsPlayer.currentChannelEpg.loadMore = false
      state.hlsPlayer.currentChannelEpg.currentProgramIdx =
        action.payload.currentProgramIdx
      state.hlsPlayer.currentChannelEpg.items.unshift(...action.payload.items)
    },
    player_hlsPlayer_currentChannelEpg_setCurrentProgramIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.hlsPlayer.currentChannelEpg.currentProgramIdx = action.payload
    },
    player_hlsPlayer_currentChannelEpg_incrementCurrentProgramIdx: (state) => {
      state.hlsPlayer.currentChannelEpg.currentProgramIdx++
    },
    player_hlsPlayer_currentChannelEpg_setIsFetching: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hlsPlayer.currentChannelEpg.isFetching = action.payload
    },
    player_hlsPlayer_currentChannelEpg_setItems: (
      state,
      action: PayloadAction<{
        prevDayValue: number
        currentProgramIdx: number
        items: IGetEpgByDayResponse[]
      }>
    ) => {
      state.hlsPlayer.currentChannelEpg.currentProgramIdx =
        action.payload.currentProgramIdx
      state.hlsPlayer.currentChannelEpg.items = action.payload.items
      state.hlsPlayer.currentChannelEpg.prevDay = action.payload.prevDayValue
    },

    // Categories
    player_categories_setCategories: (
      state,
      action: PayloadAction<IChannelsCategoriesResponse[]>
    ) => {
      state.categories.items = action.payload
    },
    player_categories_incrementActiveCategoryIdx: (state) => {
      state.categories.activeCategoryIdx++
    },
    player_categories_decrementActiveCategoryIdx: (state) => {
      state.categories.activeCategoryIdx--
    },
    player_categories_setActiveCategoryIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.categories.activeCategoryIdx = action.payload
    },
    player_categories_setSelectedCategoryIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.categories.selectedCategoryIdx = action.payload
    },

    player_categories_setChannelItemsToExtraChannelsCategory: (
      state,
      action: PayloadAction<IAddChannelsToExtraCategoryPayload>
    ) => {
      const channelsIds = useGetParsedItemFromLocalStorage<string[]>(
        action.payload.localStorageItemName
      )
      const targetCategoryIdx = state.categories.items.findIndex(
        (category) => category.id === action.payload.targetCategoryId
      )

      // get user channels
      const allChannelsCategory = state.categories.items.find(
        (category) => category.id === ALL_CHANNELS_CATEGORY_ID
      )

      // filter channels ids by user channels
      if (channelsIds) {
        const filteredChannelsIds = channelsIds.filter((channelId) =>
          allChannelsCategory?.channels.some(
            (channel) => channel.id === +channelId
          )
        )
        const channels: IChannel[] = []

        allChannelsCategory?.channels.forEach((channel) =>
          filteredChannelsIds.some((id) => {
            if (channel.id === +id) channels.push(channel)
          })
        )
        state.categories.items[targetCategoryIdx].channels = channels
      }
    },

    player_categories_addChannelItemToExtraChannelsCategory: (
      state,
      action: PayloadAction<{ id: number; item: IChannel }>
    ) => {
      const categoryIndex = state.categories.items.findIndex(
        (category) => category.id === action.payload.id
      )
      if (categoryIndex !== -1)
        state.categories.items[categoryIndex].channels.push(action.payload.item)
    },

    player_categories_removeChannelItemsFromExtraChannelsCategory: (
      state,
      action: PayloadAction<{ id: number; channelId: number }>
    ) => {
      const categoryIndex = state.categories.items.findIndex(
        (category) => category.id === action.payload.id
      )
      if (categoryIndex !== -1) {
        state.categories.items[categoryIndex].channels = state.categories.items[
          categoryIndex
        ].channels.filter((channel) => channel.id !== action.payload.channelId)
      }
    },

    // Elements
    player_elements_setHideElementsValue: (
      state,
      action: PayloadAction<number>
    ) => {
      state.elements.hideElementsValue = action.payload
    },

    player_elements_partialResetElementsState: (state) => {
      state.elements.header.leftMenu = initialState.elements.header.leftMenu
      state.elements.header.rightMenu = initialState.elements.header.rightMenu
      state.elements.footer.controls = initialState.elements.footer.controls
      state.elements.footer.playback = initialState.elements.footer.playback
    },
    player_elements_resetElementsState: (state) => {
      state.elements = initialState.elements
    },
    player_elements_toggleElements: (state) => {
      state.elements.footer.isShown = !state.elements.footer.isShown
      state.elements.header.isShown = !state.elements.header.isShown
    },
    player_elements_showElements: (state) => {
      state.elements.footer.isShown = true
      state.elements.header.isShown = true
    },
    player_elements_hideElements: (state) => {
      state.elements.footer.isShown = false
      state.elements.header.isShown = false
    },
    player_elements_setPromoIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.promo.isActive = action.payload
    },
    player_elements_setHeaderIsShown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.header.isShown = action.payload
    },
    player_elements_setFooterIsShown: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.footer.isShown = action.payload
    },

    // controls left side
    player_elements_footer_controls_parentalControl_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.footer.controls.parentalControl.isActive = action.payload
    },
    player_elements_footer_controls_favourite_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.footer.controls.favorite.isActive = action.payload
    },

    // Controls (main)
    player_elements_footer_controls_main_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.footer.controls.main.isActive = action.payload
    },
    player_elements_footer_controls_main_changePosition: (
      state,
      action: PayloadAction<TControlsMainPosition>
    ) => {
      state.elements.footer.controls.main.position = action.payload
    },

    // Controls (right side)
    player_elements_footer_controls_rightSide_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.footer.controls.rightSide.isActive = action.payload
    },

    // Playback
    player_elements_footer_playback_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.footer.playback.isActive = action.payload
    },

    // Header
    player_elements_header_leftMenu_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.header.leftMenu.isActive = action.payload
    },
    player_elements_header_rightMenu_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.elements.header.rightMenu.isActive = action.payload
    },

    //// Channels
    // Active channel actions
    player_channels_incrementActiveChannelIdx: (state) => {
      state.channels.activeChannelIdx++
    },
    player_channels_decrementActiveChannelIdx: (state) => {
      state.channels.activeChannelIdx--
    },
    player_channels_setActiveChannelIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.channels.activeChannelIdx = action.payload
    },

    // Selected channel actions
    player_channels_setSelectedChannelData: (
      state,
      action: PayloadAction<{
        categoryIdx: number
        channelIdx: number
        channelID: number
      }>
    ) => {
      state.categories.selectedCategoryIdx = action.payload.categoryIdx
      state.channels.selectedChannelIdx = action.payload.channelIdx
      state.channels.selectedChannelID = action.payload.channelID
    },
    player_channels_incrementSelectedChannelIdx: (state) => {
      state.channels.selectedChannelIdx++
    },
    player_channels_decrementSelectedChannelIdx: (state) => {
      state.channels.selectedChannelIdx--
    },
    player_channels_setSelectedChannelIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.channels.selectedChannelIdx = action.payload
    },
    player_channels_setSelectedChannelID: (
      state,
      action: PayloadAction<number>
    ) => {
      state.channels.selectedChannelID = action.payload
    },
    player_channels_setSelectedChannelDataFromLocalStorage: (state) => {
      const lastWatchedCategoryId = useGetItemFromLocalStorage(
        LAST_WATCHED_CATEGORY_ID
      )
      const lastWatchedChannelId = useGetItemFromLocalStorage(
        LAST_WATCHED_CHANNEL_ID
      )
      const filteredCategories = state.categories.items.filter(
        (category) => category.channels.length !== 0
      )
      const allChannelsCategoryIdx = filteredCategories.findIndex(
        (category) => category.id === ALL_CHANNELS_CATEGORY_ID
      )

      //  last watched data not saved in local storage
      if (!lastWatchedCategoryId && !lastWatchedChannelId) {
        state.categories.selectedCategoryIdx = allChannelsCategoryIdx
          ? allChannelsCategoryIdx
          : 0
        state.channels.selectedChannelID =
          filteredCategories[
            state.categories.selectedCategoryIdx
          ].channels[0].id

        const link =
          filteredCategories[state.categories.selectedCategoryIdx].channels[0]
            .link
        state.hlsPlayer.src = state.hlsPlayer.custom_cdn
          ? changeCdnDomain(link, state.hlsPlayer.custom_cdn)
          : link
        return
      }

      const targetCategoryIdx = filteredCategories.findIndex(
        (category) =>
          category.id ===
          (lastWatchedCategoryId ? +lastWatchedCategoryId : undefined)
      )

      // set selected category idx
      if (targetCategoryIdx < 0) {
        state.categories.selectedCategoryIdx = allChannelsCategoryIdx
          ? allChannelsCategoryIdx
          : 0
        localStorage.removeItem(LAST_WATCHED_CATEGORY_ID)
      } else {
        state.categories.selectedCategoryIdx = targetCategoryIdx
      }

      const targetChannelIdx = filteredCategories[
        state.categories.selectedCategoryIdx
      ].channels.findIndex(
        (channel) =>
          channel.id ===
          (lastWatchedChannelId ? +lastWatchedChannelId : undefined)
      )

      if (targetChannelIdx < 0) {
        state.categories.selectedCategoryIdx = allChannelsCategoryIdx
          ? allChannelsCategoryIdx
          : 0
        state.channels.selectedChannelID =
          filteredCategories[
            state.categories.selectedCategoryIdx
          ].channels[0].id
        localStorage.removeItem(LAST_WATCHED_CHANNEL_ID)
        localStorage.removeItem(LAST_WATCHED_CATEGORY_ID)
      } else {
        state.channels.selectedChannelIdx = targetChannelIdx
        state.channels.selectedChannelID =
          filteredCategories[state.categories.selectedCategoryIdx].channels[
            state.channels.selectedChannelIdx
          ].id
      }

      // set src
      const link =
        filteredCategories[state.categories.selectedCategoryIdx].channels[
          state.channels.selectedChannelIdx
        ].link
      state.hlsPlayer.src = state.hlsPlayer.custom_cdn
        ? changeCdnDomain(link, state.hlsPlayer.custom_cdn)
        : link
    },
  },
})

export const playerApi = createApi({
  reducerPath: 'playerApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  endpoints: (builder) => ({
    //https://webapp.hls.tv/api/channels/categories
    get_categories: builder.query<
      IChannelsCategoriesResponse[],
      { playlist_hash: string; promo: boolean }
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const url = `${process.env.BASE_REACT_PLAYER_API}/api/channels/categories`
        const { playlist_hash, promo } = _args
        try {
          const { data } = await axios.post<IChannelsCategoriesResponse[]>(
            url,
            { playlist_hash }
          )

          if (data) {
            // Set categories to redux store
            _api.dispatch(playerActions.player_categories_setCategories(data))

            // load initial user data from local storage
            _api.dispatch(
              appActions.app_settings_loadSettingsFromLocalStorage()
            )

            // set default parental controll password (if local storage item is null)
            if (!useGetItemFromLocalStorage(PARENTAL_CONTROL))
              localStorage.setItem(PARENTAL_CONTROL, md5('0000'))

            _api.dispatch(
              moviesActions.movies_loadRecentlyViewedEpisodesFromLocalStorage()
            )
            _api.dispatch(
              playerActions.player_categories_setChannelItemsToExtraChannelsCategory(
                {
                  targetCategoryId: FAVOURITE_CHANNELS_CATEGORY_ID,
                  localStorageItemName: FAVOURITE,
                }
              )
            )
            _api.dispatch(
              playerActions.player_categories_setChannelItemsToExtraChannelsCategory(
                {
                  targetCategoryId: BLOCKED_CHANNELS_CATEGORY_ID,
                  localStorageItemName: BLOCKED,
                }
              )
            )
            _api.dispatch(
              playerActions.player_channels_setSelectedChannelDataFromLocalStorage()
            )

            // Get timeshift epg for current channel
            await _api.dispatch(
              playerApi.endpoints.get_epg_by_day.initiate({
                from: -1,
                to: 1,
                channel_id: appState().player.channels.selectedChannelID,
                language: appState().app.language,
                timezone: dayjs().utcOffset(),
                playlist_hash: playlist_hash,
              })
            )

            // After success get channels set authenticated user
            _api.dispatch(authActions.auth_setIsAuthenticated(true))
            _api.dispatch(playerActions.player_elements_showElements())

            if (promo) {
              _api.dispatch(authActions.auth_b2bByCode_resetB2bByCode())
              _api.dispatch(authActions.auth_setIsB2bPromo(true))
            }

            // invalidate user data after device manager overlay
            if (appState().overlays.deviceManager.isInvalidateUserData) {
              _api.dispatch(dialogsActions.dialogs_confirm_resetState())
              _api.dispatch(overlaysActions.overlays_deviceManager_reset())

              await _api.dispatch(authApi.util.invalidateTags(['user_info']))
              await _api.dispatch(authApi.util.invalidateTags(['profile_page']))
            }
            _api.dispatch(playerApi.util.resetApiState())
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              t('errors:auth:error-get-playlist')
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    // https://cdnua01.hls.tv/timeshift/get
    get_timeshift_url: builder.query<
      IGetTimeshiftUrlResponse,
      IGetTimeshiftUrlRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const timeshift_cdn = appState().auth.authSuccessData
          ?.timeshift_cdn as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${timeshift_cdn}/timeshift/get/${playlist}/${_args.channel_id}/${_args.timestamp}/index.json`

        try {
          const { data } = await axios.get<IGetTimeshiftUrlResponse>(url)

          if (appState().player.hlsPlayer.isLive) {
            _api.dispatch(playerActions.player_hlsPlayer_setIsLive(false))
          }

          _api.dispatch(
            playerActions.player_channels_setSelectedChannelID(_args.channel_id)
          )

          if (_args.setSource)
            _api.dispatch(
              playerActions.player_hlsPlayer_setSrc(
                `${timeshift_cdn}${data.m3u8}`
              )
            )
          if (appState().player.hlsPlayer.isRewind) {
            _api.dispatch(playerActions.player_hlsPlayer_setIsRewind(false))
            _api.dispatch(playerActions.player_hlsPlayer_resetRewind())
          }
          _api.dispatch(
            playerActions.player_hlsPlayer_timeshift_setLastWatchedTimestamp(
              null
            )
          )
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError

          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              `${t('errors:timeshift:archive')} "${_args.targetProgram}" ${t(
                'errors:timeshift:unavailable'
              )}`
            )
          )
          _api.dispatch(dialogsActions.dialogs_error_setErrorCode(`${status}`))
          _api.dispatch(playerActions.player_hlsPlayer_setIsLive(true))
          if (appState().player.hlsPlayer.isRewind) {
            _api.dispatch(playerActions.player_hlsPlayer_setIsRewind(false))
            _api.dispatch(playerActions.player_hlsPlayer_resetRewind())
          }
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    // https://cdnua01.hls.tv/timeshift/get
    post_timeshift_url: builder.mutation<
      IGetTimeshiftUrlResponse,
      IGetTimeshiftUrlRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const timeshift_cdn = appState().auth.authSuccessData
          ?.timeshift_cdn as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${timeshift_cdn}/timeshift/get/${playlist}/${_args.channel_id}/${_args.timestamp}/index.json`

        try {
          const { data } = await axios.get<IGetTimeshiftUrlResponse>(url)
          if (appState().player.hlsPlayer.isLive)
            _api.dispatch(playerActions.player_hlsPlayer_setIsLive(false))

          _api.dispatch(
            playerActions.player_channels_setSelectedChannelID(_args.channel_id)
          )

          if (_args.setSource)
            _api.dispatch(
              playerActions.player_hlsPlayer_setSrc(
                `${timeshift_cdn}${data.m3u8}`
              )
            )
          if (appState().player.hlsPlayer.isRewind) {
            _api.dispatch(playerActions.player_hlsPlayer_setIsRewind(false))
            _api.dispatch(playerActions.player_hlsPlayer_resetRewind())
          }
          _api.dispatch(
            playerActions.player_hlsPlayer_timeshift_setLastWatchedTimestamp(
              null
            )
          )
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError

          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              `${t('errors:timeshift:archive')} "${_args.targetProgram}" ${t(
                'errors:timeshift:unavailable'
              )}`
            )
          )
          _api.dispatch(dialogsActions.dialogs_error_setErrorCode(`${status}`))
          _api.dispatch(playerActions.player_hlsPlayer_setIsLive(true))
          if (appState().player.hlsPlayer.isRewind) {
            _api.dispatch(playerActions.player_hlsPlayer_setIsRewind(false))
            _api.dispatch(playerActions.player_hlsPlayer_resetRewind())
          }
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://webapp.hls.tv/api/channels/epg/by-days
    get_epg_by_day: builder.mutation<
      IGetEpgByDayResponse[],
      GetEpgByDayRequest
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_REACT_PLAYER_API}/api/channels/epg/by-days`
        try {
          const { data } = await axios.post<IGetEpgByDayResponse[]>(url, _args)

          if (_args.from === -1 && _args.to === 1 && !data.length) {
            _api.dispatch(
              playerActions.player_hlsPlayer_resetStateAfterSwitchChannel()
            )
            _api.dispatch(
              playerActions.player_hlsPlayer_timeshift_setTimeshiftValue(
                dayjs().unix()
              )
            )
            return { data }
          }

          if (_args.from > 0 && _args.to > 0) {
            _api.dispatch(
              playerActions.player_hlsPlayer_currentChannelEpg_pushItems(data)
            )
          }
          if (_args.from < 0 && _args.to < 0) {
            _api.dispatch(
              playerActions.player_hlsPlayer_currentChannelEpg_unshifItems({
                items: data,
                currentProgramIdx: data.length - 1,
              })
            )
          }
          if (_args.from === -1 && _args.to === 1) {
            _api.dispatch(
              playerActions.player_hlsPlayer_resetStateAfterSwitchChannel()
            )
            _api.dispatch(
              playerActions.player_hlsPlayer_timeshift_setTimeshiftValue(
                dayjs().unix()
              )
            )

            const timeNow = dayjs().unix()
            data.forEach((program, index) => {
              if (program.start_at <= timeNow && program.stop_at >= timeNow) {
                _api.dispatch(
                  playerActions.player_hlsPlayer_currentChannelEpg_setItems({
                    currentProgramIdx: index,
                    items: data,
                    prevDayValue: _args.from,
                  })
                )
              }
            })
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              t('errors:auth:error-get-programs')
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://webapp.hls.tv/api/movies/get-range
    get_epg_range: builder.mutation<IGetEpgRangeResponse, IGetEpgRangeRequest>({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const playlist_hash = appState().auth.authSuccessData
          ?.playlist as string
        const timeshift_cdn = appState().auth.authSuccessData
          ?.timeshift_cdn as string
        try {
          const url = `${process.env.BASE_REACT_PLAYER_API}/api/movies/get-range`

          const { data } = await axios.post<IGetEpgRangeResponse>(url, {
            playlist_hash,
            ..._args,
          })

          if (data && data.programs.length) {
            // find program idx
            const currentProgramIdx = data.programs.findIndex(
              (program) =>
                program.start_at <= _args.start_at &&
                _args.start_at < program.stop_at
            )

            // find selected channelIdx
            const selectedChannelIdx =
              appState().player.categories.items[0].channels.findIndex(
                (channel) => channel.id === _args.channel_id
              )

            const { data: timeshiftUrl, error } = await _api.dispatch(
              playerApi.endpoints.get_timeshift_url.initiate(
                {
                  channel_id: _args.channel_id,
                  timestamp: _args.start_at,
                  setSource: false,
                  targetProgram: _args.targetProgram,
                },
                { forceRefetch: true }
              )
            )

            if (error) {
              const err = error as IError
              return {
                error: err,
              }
            }

            if (timeshiftUrl) {
              _api.dispatch(
                playerActions.player_channels_setSelectedChannelData({
                  categoryIdx: 0,
                  channelID: _args.channel_id,
                  channelIdx: selectedChannelIdx,
                })
              )
              _api.dispatch(
                playerActions.player_hlsPlayer_setSrc(
                  `${timeshift_cdn}${timeshiftUrl.m3u8}`
                )
              )

              _api.dispatch(
                playerActions.player_hlsPlayer_currentChannelEpg_setItems({
                  currentProgramIdx,
                  prevDayValue: data.from,
                  items: data.programs,
                })
              )

              _api.dispatch(
                playerActions.player_hlsPlayer_timeshift_setTimeshiftValue(
                  _args.start_at
                )
              )
              // set focus on play/pause btn
              _api.dispatch(
                playerActions.player_elements_footer_controls_main_changePosition(
                  'PlayPause'
                )
              )
              // show live range if last catchup program stop_at < time now
              if (data.programs[currentProgramIdx].stop_at < dayjs().unix()) {
                _api.dispatch(playerActions.player_hlsPlayer_setIsCatchup(true))
              }

              _api.dispatch(playerActions.player_elements_showElements())
              _api.dispatch(playerActions.player_hlsPlayer_setIsActive(false))

              // set last channel to storage
              localStorage.setItem(LAST_WATCHED_CATEGORY_ID, '0')
              localStorage.setItem(
                LAST_WATCHED_CHANNEL_ID,
                `${_args.channel_id}`
              )
            }
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const playerReducer = playerSlice.reducer
export const playerActions = playerSlice.actions

export const {
  useLazyGet_categoriesQuery,
  useLazyGet_timeshift_urlQuery,
  usePost_timeshift_urlMutation,
  useGet_epg_by_dayMutation,
  useGet_epg_rangeMutation,
} = playerApi
