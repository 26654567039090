import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ReconnectDialog } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'reconnect'*/ '@components/Dialogs/ReconnectDialog/ReconnectDialog'
    ),
  'ReconnectDialog'
)

export const LazyReconnectDialog: FC = () => {
  const isOpened = useTypedSelector((state) => state.dialogs.reconnect.isOpened)

  if (!isOpened) return null

  return (
    <Suspense fallback={null}>
      <ReconnectDialog />
    </Suspense>
  )
}
