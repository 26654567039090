import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'
import { useOpenContinueWatching } from '@hooks/dialogs/useOpenContinueWatching'

import { lazyImport } from '@utils/lazyImport'

const { ContinueWatching } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'continue-watching'*/ '@components/Dialogs/ContinueWatching/ContinueWatching'
    ),
  'ContinueWatching'
)

export const LazyContinueWatching: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.continueWatching.isOpened
  )

  useOpenContinueWatching()
  
  if (!isOpened) return null

  return (
    <Suspense fallback={null}>
      <ContinueWatching />
    </Suspense>
  )
}
