import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  IDialogsInitialState,
  TConfirmDialogPosition,
} from '@assets/types/dialogs.types'
import { FilteredPlanId } from '@assets/types/app.types'
import { TDeviceDestination } from '@components/UIElements/Device/Device'

const initialState: IDialogsInitialState = {
  error: {
    isOpened: false,
    code: null,
    description: null,
    submit: {
      isActive: true,
    },
    cancel: {
      isActive: false,
    },
  },
  retry: {
    isOpened: false,
  },
  reconnect: {
    reconnectErrorCode: null,
    servers: [process.env.BASE_CDN_URL, process.env.BACKUP_CDN_URL],
    authIndexUrl: 0,
    isOpened: false,
    attempts: 0,
  },
  info: {
    isOpened: false,
    message: null,
  },
  reloadApp: {
    isOpened: false,
    message: null,
  },
  confirm: {
    position: 'submit',
    message: null,
    changePlan: {
      isOpened: false,
      planId: null,
    },
    unbind: {
      isOpened: false,
      unbindFrom: null,
      uniq: null,
    },
    exitApp: {
      isOpened: false,
    },
    exitPay: {
      isOpened: false,
    },
    exitMovies: {
      isOpened: false,
    },
    continueWatching: {
      isOpened: false,
    },
    replenish: {
      isOpened: false
    }
  },
}

export const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    // Error dialog
    dialogs_error_resetErrorDialogState: (state) => {
      state.error = initialState.error
    },
    dialogs_error_setErrorCode: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.error.code = action.payload
    },
    dialogs_error_setDescription: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.error.description = action.payload
    },
    dialogs_error_setErrorDialogIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.error.isOpened = action.payload
    },
    dialogs_error_submit_setSubmitIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.error.submit.isActive = action.payload
    },
    dialogs_error_submit_click: (state) => {
      state.error.submit.isActive = true
      state.error.cancel.isActive = false
    },
    dialogs_error_cancel_setCancelIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.error.cancel.isActive = action.payload
    },

    // Retry
    dialogs_retry_setRetryDialogIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.retry.isOpened = action.payload
    },
    dialogs_retry_resetRetryDialogState: (state) => {
      state.retry = initialState.retry
    },

    // Reconnect dialog
    dialogs_reconnect_setReconnectDialogIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.reconnect.isOpened = action.payload
    },
    dialogs_reconnect_incrementReconnectAttempts: (state) => {
      state.reconnect.attempts++
    },
    dialogs_reconnect_setAuthIndexUrl: (state) => {
      state.reconnect.authIndexUrl =
        state.reconnect.authIndexUrl === 0
          ? (state.reconnect.authIndexUrl = 1)
          : (state.reconnect.authIndexUrl = 0)
    },
    dialogs_reconnect_setReconnectErrorCode: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.reconnect.reconnectErrorCode = action.payload
    },
    dialogs_reconnect_resetReconnectState: (state) => {
      state.reconnect = initialState.reconnect
    },

    // info dialog
    dialogs_info_resetInfoDialogState: (state) => {
      state.info = initialState.info
    },
    dialogs_info_setInfoDialogIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.info.isOpened = action.payload
    },
    dialogs_info_setInfoDialogText: (state, action: PayloadAction<string>) => {
      state.info.message = action.payload
    },

    // reload app
    dialogs_reloadApp_resetReloadAppDialogState: (state) => {
      state.reloadApp = initialState.reloadApp
    },
    dialogs_reloadApp_setReloadAppIsOpened: (
      state,
      action: PayloadAction<{isOpened: boolean, message: string}>
    ) => {
      state.reloadApp.message = action.payload.message
      state.reloadApp.isOpened = action.payload.isOpened
    },

    // confirm dialogs
    dialogs_confirm_changePosition: (
      state,
      action: PayloadAction<TConfirmDialogPosition>
    ) => {
      state.confirm.position = action.payload
    },
    dialogs_confirm_setMessage: (state, action: PayloadAction<string>) => {
      state.confirm.message = action.payload
    },
    dialogs_confirm_resetState: (state) => {
      state.confirm = initialState.confirm
    },

    // change plan
    dialogs_confirm_changePlan_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.changePlan.isOpened = action.payload
    },
    dialogs_confirm_setPlanId: (
      state,
      action: PayloadAction<FilteredPlanId>
    ) => {
      state.confirm.changePlan.planId = action.payload
    },

    // unbind device
    dialogs_confirm_unbind_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.unbind.isOpened = action.payload
    },
    dialogs_confirm_unbind_setUniq: (state, action: PayloadAction<string>) => {
      state.confirm.unbind.uniq = action.payload
    },
    dialogs_confirm_unbind_setUnbindFrom: (
      state,
      action: PayloadAction<TDeviceDestination>
    ) => {
      state.confirm.unbind.unbindFrom = action.payload
    },

    // exit app
    dialogs_confirm_exitApp_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.exitApp.isOpened = action.payload
    },

    // exit pay
    dialogs_confirm_exitPay_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.exitPay.isOpened = action.payload
    },

    // exit movies
    dialogs_confirm_exitMovies_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.exitMovies.isOpened = action.payload
    },

    // continue watching
    dialogs_confirm_continueWatching_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.continueWatching.isOpened = action.payload
    },

    //replenish
    dialogs_confirm_replenish_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.confirm.replenish.isOpened = action.payload
    },
  },
})

export const dialogsReducer = dialogsSlice.reducer
export const dialogsActions = dialogsSlice.actions
