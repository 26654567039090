import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'
import { useErrorHandler } from '@hooks/dialogs/useErrorHandler'

import { lazyImport } from '@utils/lazyImport'

const { ErrorDialog } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'error-dialog'*/ '@components/Dialogs/Common/ErrorDialog/ErrorDialog'
    ),
  'ErrorDialog'
)

export const LazyErrorDialog: FC = () => {
  const isOpened = useTypedSelector((state) => state.dialogs.error.isOpened)
  const data = useErrorHandler()

  if (!isOpened) return null

  return (
    <Suspense fallback={null}>
      <ErrorDialog
        description={data.errorDescription}
        submitButtonName={data.submitButtonName}
        submitHandler={data.submitHandler}
        cancelButtonName={
          __PLATFORM__ !== 'desktop' ? data.cancelButtonName : undefined
        }
        cancelHandler={
          __PLATFORM__ !== 'desktop' ? data.cancelHandler : undefined
        }
      />
    </Suspense>
  )
}
