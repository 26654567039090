import { useTypedSelector } from '@hooks/common/useTypedSelector'
import { useChannelExistsInExtraCategory } from '@hooks/player/leftMenu/categories/extraCategories/useChannelExistsInExtraCategory'

import { BLOCKED_CHANNELS_CATEGORY_ID } from '@utils/vars'

export const useGetChannelIsBlocked = (
  targetChannelId?: number
): boolean => {
  const channelsIsUnblocked = useTypedSelector(
    (state) => state.app.channelsIsUnblocked
  )
  const isBlocked = useChannelExistsInExtraCategory(
    BLOCKED_CHANNELS_CATEGORY_ID,
    targetChannelId
  )
 
  if (!isBlocked) return false
  if (isBlocked && !channelsIsUnblocked) return true
  
  return false
} 
