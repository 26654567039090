import i18n from '@assets/i18n/config'

import { useTypedAction } from '@hooks/common/useTypedActions'
import { useGetItemFromLocalStorage } from '@hooks/app/localStorage/useGetItemFromLocalStorage'

import { LANGUAGE } from '@utils/vars'

import { TLanguage } from '@assets/types/app.types'

export const useLoadLanguage = () => {
  const {
    app_setLanguage,
    appRightMenu_subMenu_settings_language_setPosition,
  } = useTypedAction()

  return () => {
    const savedLanguage = useGetItemFromLocalStorage(LANGUAGE)
    if (savedLanguage) {
      const language = savedLanguage as TLanguage
      if (language === 'en' || language === 'ua') {
        if (language === 'en') {
          i18n.changeLanguage('en')
        }
        if (language === 'ua') {
          i18n.changeLanguage('ua')
        }
        app_setLanguage(language)
        appRightMenu_subMenu_settings_language_setPosition(language)
      } else {
        localStorage.setItem(LANGUAGE, 'ua')
        app_setLanguage('ua')
        appRightMenu_subMenu_settings_language_setPosition('ua')
        i18n.changeLanguage('ua')
      }
    } else {
      appRightMenu_subMenu_settings_language_setPosition('ua')
      i18n.changeLanguage('ua')
      app_setLanguage('ua')
    }
  }
}
