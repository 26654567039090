import { useState, useEffect } from 'react'
import { useGet_middleware_auth_dataMutation } from '@store/slices/auth.slice'

import { OMEGATV_MAC_ADDRESS, OMEGATV_MODEL_NAME } from '@utils/vars'

import { useTypedAction } from '@hooks/common/useTypedActions'
import { useTypedSelector } from '@hooks/common/useTypedSelector'
import { useUpdateEffect } from '@hooks/common/useUpdateEffect'
import { TWebosWindow } from '@hooks/auth/webos/useGetWebosModel'

const setAuthParams = (deviceModel: string, mac: string) => {
  const storage = window.localStorage
  storage.setItem(OMEGATV_MAC_ADDRESS, mac)
  storage.setItem(OMEGATV_MODEL_NAME, deviceModel)
}

export const useAuth = () => {
  const storage = window.localStorage
  const [isCreated, setIsCreated] = useState<boolean>(false)

  const isAuthenticated = useTypedSelector(
    (state) => state.auth.isAuthenticated
  )

  const { dialogs_error_setErrorCode } = useTypedAction()

  const [getAuthData, { isLoading, data }] =
    useGet_middleware_auth_dataMutation({ fixedCacheKey: 'shared-auth' })

  const deviceUniqCode = storage.getItem(OMEGATV_MAC_ADDRESS)

  useEffect(() => {
    switch (__PLATFORM__) {
    case 'webos': {
      import(/* webpackChunkName: 'webos-auth'*/ './webos/devModule').then(
        () =>
          import(
            /* webpackChunkName: 'webos-auth'*/ './webos/useGetWebosModel'
          ).then((module) => {
            const getWebOSDeviceData = () => {
              const deviceModel = module.useGetWebosModel()
              const webosWindow = window as TWebosWindow
              webosWindow.webOSDev.LGUDID({
                onSuccess: function (res: { id: string }) {
                  if (window.Sentry && window.Sentry.setTag) {
                    window?.Sentry?.setTag('MODEL', deviceModel)
                    window?.Sentry?.setTag('SERIAL', res.id)
                  }
                  if (deviceUniqCode) {
                    setIsCreated(true)
                    return
                  }
                  setAuthParams(deviceModel, res.id)
                  setIsCreated(true)
                },
                onFailure: function () {
                  dialogs_error_setErrorCode('7099')
                },
              })
            }
            if (__MODE__ === 'production') {
              getWebOSDeviceData()
            } else {
              if (deviceUniqCode) {
                return setIsCreated(true)
              }
              getWebOSDeviceData()
            }
          })
      )
      break
    }

    case 'tizen': {
      import(
        /* webpackChunkName: "tizen-auth"*/ './tizen/useGetTizenCredentials'
      ).then((module) => {
        const data = module.useGetTizenCredentials()
        if (__MODE__ === 'production') {
          if (!data) return dialogs_error_setErrorCode('7099')
          if (window.Sentry && window.Sentry.setTag) {
            window?.Sentry?.setTag('MODEL', data.deviceModel)
            window?.Sentry?.setTag('SERIAL', data.mac)
          }
          if (deviceUniqCode) {
            setIsCreated(true)
            return
          }
          setAuthParams(data.deviceModel, data.mac)
          setIsCreated(true)
        } else {
          if (deviceUniqCode) {
            setIsCreated(true)
            return
          } else {
            if (data) {
              setAuthParams(data.deviceModel, data.mac)
              setIsCreated(true)
            } else {
              dialogs_error_setErrorCode('7099')
            }
          }
        }
      })
      break
    }

    case 'desktop': {
      if (__MODE__ === 'production') {
        setIsCreated(true)
      } else {
        if (deviceUniqCode) {
          return setIsCreated(true)
        } else {
          dialogs_error_setErrorCode('7099')
        }
      }
      break
    }

    default:
      break
    }
  }, [])

  useUpdateEffect(() => {
    if (isCreated && !isAuthenticated) getAuthData()
  }, [isCreated, isAuthenticated])

  return { isLoading, data, isAuthenticated }
}
