import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ConfirmExitApp } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'confirm-exit-app'*/ '@components/Dialogs/ConfirmExitApp/ConfirmExitApp'
    ),
  'ConfirmExitApp'
)

export const LazyConfirmExitApp: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.exitApp.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <ConfirmExitApp />}</Suspense>
}
