import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { Payment } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'payment'*/ '@components/Overlays/Payment/Payment'
    ),
  'Payment'
)

export const LazyPayment: FC = () => {
  const isOpened = useTypedSelector((state) => state.overlays.payment.isOpened)

  return <Suspense fallback={null}>{isOpened && <Payment />}</Suspense>
}
