import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'

import {
  IGetMoviesCategoriesResponse,
  IMovieCategory,
  IMoviesAsideInitialState,
} from '@assets/types/movies/aside.types'

import { dialogsActions } from '@store/slices/dialogs.slice'
import { TLanguage } from '@assets/types/app.types'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'

const initialState: IMoviesAsideInitialState = {
  asideIsActive: false,
  fetchedCategoryName: null,
  backToTvIsActive: false,
  recentlyIsActive: false,
  allIsActive: false,
  main: {
    activeCategoryIdx: 0,
    isActive: false,
  },
}

export const moviesAsideSlice = createSlice({
  name: 'moviesAside',
  initialState,
  reducers: {
    movies_aside_reset: () => initialState,
    movies_aside_setAsideIsActive: (state, action: PayloadAction<boolean>) => {
      state.asideIsActive = action.payload
    },
   
    // extra categories
    movies_aside_setBackToTvIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.backToTvIsActive = action.payload
    },
    movies_aside_setRecentlyIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.recentlyIsActive = action.payload
    },
    movies_aside_setAllIsActive: (state, action: PayloadAction<boolean>) => {
      state.allIsActive = action.payload
    },
    movies_aside_recentlyClickHandler: (state) => {
      state.backToTvIsActive = false
      state.allIsActive = false
      state.main.isActive = false
      state.recentlyIsActive = true
      state.asideIsActive = true
      state.main.activeCategoryIdx = 0
    },
    movies_aside_allClickHandler: (state) => {
      state.backToTvIsActive = false
      state.recentlyIsActive = false
      state.main.isActive = false
      state.allIsActive = true
      state.asideIsActive = true
      state.main.activeCategoryIdx = 0
    },

    // main categories
    movies_aside_incrementActiveCategory: (state) => {
      state.main.activeCategoryIdx++
    },
    movies_aside_decrementActiveCategory: (state) => {
      state.main.activeCategoryIdx--
    },
    movies_aside_setActiveCategoryIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.main.activeCategoryIdx = action.payload
    },
    movies_aside_setFetchedCategory: (
      state,
      action: PayloadAction<{ index: number; name: string }>
    ) => {
      state.main.activeCategoryIdx = action.payload.index
      state.fetchedCategoryName = action.payload.name
    },
    movies_aside_setFetchedCategoryName: (
      state,
      action: PayloadAction<string>
    ) => {
      state.fetchedCategoryName = action.payload
    },
    movies_aside_main_setMainIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.main.isActive = action.payload
    },
    movies_aside_main_clickHandler: (state) => {
      state.backToTvIsActive = false
      state.recentlyIsActive = false
      state.allIsActive = false
      state.main.isActive = true
      state.asideIsActive = true
    },
  },
})

export const moviesAsideApi = createApi({
  reducerPath: 'moviesAsideApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  endpoints: (builder) => ({
    // https://cdnua01.hls.tv/v1/catalog/tags.json
    movies_get_categories: builder.query<
      IMovieCategory[],
      { language: TLanguage }
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_CDN_URL}/v1/catalog/tags.json?lang=${_args.language}`

        try {
          const { data } = await axios.get<IGetMoviesCategoriesResponse>(url)

          // sort categpries by priority
          const sortedCategories = data.tags.sort(
            (a, b) => a.priority - b.priority
          )

          return { data: sortedCategories }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const moviesAsideReducer = moviesAsideSlice.reducer
export const moviesAsideActions = moviesAsideSlice.actions

export const { useMovies_get_categoriesQuery } = moviesAsideApi
