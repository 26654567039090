import { createSelector } from '@reduxjs/toolkit'

import { IChannel } from '@assets/types/player.types'

import { AppState } from '@store/store'
import { ALL_CHANNELS_CATEGORY_ID } from '@utils/vars'

const selectAllCategories = (state: AppState) => state.player.categories.items
const selectedChannelId = (state: AppState) =>
  state.player.channels.selectedChannelID
const selectCurrentProgramIdx = (state: AppState) =>
  state.player.hlsPlayer.currentChannelEpg.currentProgramIdx
const selectEpg = (state: AppState) =>
  state.player.hlsPlayer.currentChannelEpg.items
const selectChannelKey = (state: AppState) =>
  state.app.findChannelByKey.channelKey

// select channel in target category
export const selectChannelFromTargetCategory = createSelector(
  [
    selectAllCategories,
    selectedChannelId,
    (_, params: { categoryId: number; channelId?: number }) => params,
  ],

  (allCategories, selectedChannel, { categoryId, channelId }) =>
    allCategories
      ?.find((category) => category.id === categoryId)
      ?.channels.find((channel) => {
        if (channelId) return channel.id === channelId
        return channel.id === selectedChannel
      })
)

// select current channel's program
export const selectCurrentChannelsProgram = createSelector(
  [selectEpg, selectCurrentProgramIdx],
  (epg, currentProgramIdx) => epg[currentProgramIdx]?.title
)

// select channel by key
export const selectChannelIndexByKey = createSelector(
  [selectChannelKey, (_, channels: IChannel[] | undefined) => channels],
  (channelKey, channels) =>
    channels?.findIndex((channel) => channel.key === Number(channelKey))
)

// get channel hd status
export const selectChannelHdStatus = createSelector(
  [
    selectAllCategories,
    (_, targetChannelId: number | undefined) => targetChannelId,
  ],
  (allCategories, selectedChannelId) =>
    allCategories
      ?.find((category) => category.id === ALL_CHANNELS_CATEGORY_ID)
      ?.channels.find((channel) => channel.id === selectedChannelId)?.hd
)
