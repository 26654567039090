import React, { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

import { AppWrapper } from '@components/UIElements/AppWrapper/AppWrapper'
import { Header } from '@components/UIElements/Dialogs/Header/Header'

import ErrorIcon from '@assets/images/dialogs/error.svg'
import ReconnectIcon from '@assets/images/dialogs/reconnect.svg'
import InfoWhiteIcon from '@assets/images/dialogs/info-white.svg'

import styles from '@components/Dialogs/dialog.module.scss'

type TCommonDialogProps = {
  children: ReactNode
}

type TErrorDialogProps = TCommonDialogProps & {
  errorDialog: boolean
  errorCode: string | null
  reconnectDialog?: never
  infoDialog?: never
}
type TReconnectDialogProps = TCommonDialogProps & {
  reconnectDialog: boolean
  errorCode?: never
  infoDialog?: never
  errorDialog?: never
}

type TInfoDialogProps = TCommonDialogProps & {
  infoDialog: boolean
  errorCode?: never
  errorDialog?: never
  reconnectDialog?: never
}

type DialogProps = TErrorDialogProps | TReconnectDialogProps | TInfoDialogProps

export const Dialog: FC<DialogProps> = ({
  children,
  errorCode,
  errorDialog,
  infoDialog,
  reconnectDialog,
}) => {
  const { t } = useTranslation('dialogs')

  const icon: string = errorDialog
    ? ErrorIcon
    : reconnectDialog
      ? ReconnectIcon
      : InfoWhiteIcon

  const text = errorDialog
    ? `${t('error')} ${errorCode && `(${errorCode})`}`
    : infoDialog
      ? t('information')
      : t('reconnect')

  return createPortal(
    <AppWrapper className={styles.dialog}>
      <div className={styles.dialogWrapper}>
        <Header icon={icon} text={text} />
        <div className={styles.dialogBody}>{children}</div>
      </div>
    </AppWrapper>,
    document.body
  )
}
