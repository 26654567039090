import React, { FC, memo } from 'react'

import styles from '@components/UIElements/Dialogs/Message/message.module.scss'

interface IMessageProps {
  description: string
}

const MessageComponent: FC<IMessageProps> = ({
  description,
}) => {
  return (
    <div className={styles.message}>
      <span className={styles.messageText}>{description}</span>
    </div>
  )
}

export const Message = memo(MessageComponent)
