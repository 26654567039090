import React, { FC } from 'react'

import { LazyEditDevice } from '@components/Overlays/EditDevice/LazyEditDevice'
import { LazyPayment } from '@components/Overlays/Payment/LazyPayment'
import { LazyPrivacyPolicy } from '@components/Overlays/PrivacyPolicy/LazyPrivacyPolicy'
import { LazyVoucher } from '@components/Overlays/ActivateVoucher/LazyVoucher'
import { LazyChoosePlan } from '@components/Overlays/ChoosePlan/LazyChoosePlan'
import { LazyDeviceManager } from '@components/Overlays/DeviceManager/LazyDeviceManager'

export const Overlays: FC = () => {
  return (
    <>
      <LazyEditDevice />
      <LazyPayment />
      <LazyPrivacyPolicy />
      <LazyVoucher />
      <LazyChoosePlan />
      <LazyDeviceManager />
    </>
  )
}
