import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { appActions } from '@store/slices/app.slice'
import { moviesActions } from '@store/slices/movies/movies.slice'
import { moviesAsideActions } from '@store/slices/movies/aside.slice'
import { moviesFilmListActions } from '@store/slices/movies/filmlist.slice'
import { moviesFilmDetailsActions } from '@store/slices/movies/filmDetails.slice'
import { authActions } from '@store/slices/auth.slice'
import { recentlyViewedActions } from '@store/slices/movies/recentlyViewed'
import { appLeftMenuActions } from '@store/slices/app.left.menu.slice'
import { appRightMenuActions } from '@store/slices/app.right.menu.slice'

import { playerActions } from '@store/slices/player.slice'
import { overlaysActions } from '@store/slices/overlays.slice'
import { dialogsActions } from '@store/slices/dialogs.slice'

const actions = {
  ...authActions,
  ...playerActions,
  ...appActions,
  ...overlaysActions,
  ...dialogsActions,
  ...appLeftMenuActions,
  ...appRightMenuActions,
  ...moviesActions,
  ...moviesAsideActions,
  ...moviesFilmListActions,
  ...moviesFilmDetailsActions,
  ...recentlyViewedActions,
}

export const useTypedAction = () => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}
