import React, { FC } from 'react'

import { InfoDialog } from '@components/Dialogs/Common/InfoDialog/InfoDialog'
import { LazyConfirmChangePlan } from '@components/Dialogs/ConfirmChangePlan/LazyConfirmChangePlan'
import { LazyConfirmUnbind } from '@components/Dialogs/ConfirmUnbind/LazyConfirmUnbind'
import { LazyConfirmExitPay } from '@components/Dialogs/ConfirmExitPay/LazyConfirmExitPay'
import { LazyContinueWatching } from '@components/Dialogs/ContinueWatching/LazyContinueWatching'
import { LazyReloadApp } from '@components/Dialogs/ReloadApp/LazyReloadApp'
import { LazyConfirmReplenish } from '@components/Dialogs/ConfirmReplenish/LazyConfirmReplenish'
import { LazyReconnectDialog } from '@components/Dialogs/ReconnectDialog/LazyReconnectDialog'
import { LazyConfirmExitApp } from '@components/Dialogs/ConfirmExitApp/LazyConfirmExitApp'
import { LazyErrorDialog } from '@components/Dialogs/Common/ErrorDialog/LazyErrorDialog'

export const Dialogs: FC = () => {
  return (
    <>
      <LazyErrorDialog />
      <LazyReloadApp />
      <InfoDialog />
      <LazyConfirmChangePlan />
      <LazyConfirmUnbind />
      <LazyConfirmExitPay />
      <LazyContinueWatching />
      <LazyConfirmReplenish />
      <LazyConfirmExitApp />
      <LazyReconnectDialog />
    </>
  )
}
