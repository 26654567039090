// common
export const KEY_ENTER = 13
export const KEY_LEFT = 37
export const KEY_TOP = 38
export const KEY_RIGHT = 39

export const KEY_DOWN = 40
export const KEY_BACKSPACE = 8
export const KEY_0_TV = 48
export const KEY_1_TV = 49
export const KEY_2_TV = 50
export const KEY_3_TV = 51
export const KEY_4_TV = 52
export const KEY_5_TV = 53
export const KEY_6_TV = 54
export const KEY_7_TV = 55
export const KEY_8_TV = 56
export const KEY_9_TV = 57
export const KEY_0_DESKTOP = 96
export const KEY_1_DESKTOP = 97
export const KEY_2_DESKTOP = 98
export const KEY_3_DESKTOP = 99
export const KEY_4_DESKTOP = 100
export const KEY_5_DESKTOP = 101
export const KEY_6_DESKTOP = 102
export const KEY_7_DESKTOP = 103
export const KEY_8_DESKTOP = 104
export const KEY_9_DESKTOP = 105
export const KEY_PAUSE = 19
export const KEY_PLAY = 415
export const KEY_REWIND_BACKWARD = 412
export const KEY_REWIND_FORWARD = 417

// webos
export const KEY_BACK_WEBOS = 461
export const KEY_HIDE_CURSOUR_WEBOS = 1537
export const KEY_WEBOS_CHANNEL_UP = 33
export const KEY_WEBOS_CHANNEL_DOWN = 34

// tizen
export const KEY_BACK_TIZEN = 10009
export const KEY_EXIT_TIZEN = 10182
export const KEY_TIZEN_CHANNEL_UP = 427
export const KEY_TIZEN_CHANNEL_DOWN = 428

// desktop
export const KEY_SPACEBAR = 32
export const KEY_ESCAPE = 27



