import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

// uk
import errors_ua from '@assets/i18n/locales/ua/errors/errors.json'
import common_ua from '@assets/i18n/locales/ua/common/common.json'
import films_ua from '@assets/i18n/locales/ua/films/films.json'
import auth_ua from '@assets/i18n/locales/ua/auth/auth.json'
import dialogs_ua from '@assets/i18n/locales/ua/dialogs/dialogs.json'
import player_ua from '@assets/i18n/locales/ua/player/player.json'
import rightMenu_ua from '@assets/i18n/locales/ua/right-menu/right-menu.json'
import overlays_ua from '@assets/i18n/locales/ua/overlays/overlays.json'
import pageNotFound_ua from '@assets/i18n/locales/ua/pageNotFound/page-not-found.json'

// en
import errors_en from '@assets/i18n/locales/en/errors/errors.json'
import common_en from '@assets/i18n/locales/en/common/common.json'
import films_en from '@assets/i18n/locales/en/films/films.json'
import auth_en from '@assets/i18n/locales/en/auth/auth.json'
import dialogs_en from '@assets/i18n/locales/en/dialogs/dialogs.json'
import player_en from '@assets/i18n/locales/en/player/player.json'
import rightMenu_en from '@assets/i18n/locales/en/right-menu/right-menu.json'
import overlays_en from '@assets/i18n/locales/en/overlays/overlays.json'
import pageNotFound_en from '@assets/i18n/locales/en/pageNotFound/page-not-found.json'

export const resources = {
  ua: {
    errors: errors_ua,
    common: common_ua,
    films: films_ua,
    auth: auth_ua, 
    dialogs: dialogs_ua,
    player: player_ua,
    rightMenu: rightMenu_ua,
    overlays: overlays_ua,
    pageNotFound: pageNotFound_ua
  },
  en: {
    errors: errors_en,
    common: common_en,
    films: films_en,
    auth: auth_en, 
    dialogs: dialogs_en,
    player: player_en,
    rightMenu: rightMenu_en,
    overlays: overlays_en,
    pageNotFound: pageNotFound_en
  }
} as const

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: 'v2'
})

export default i18n