import { useSetLastWatchedCatchupProgram } from '@hooks/player/useSetLastWatchedCatchupProgram'

export const useExitFromApp = () => {
  window.opener = null;
  window.open('', '_self');
  window.close();
}

export const useCloseApp = (): (() => void) => {
  const setLastWatchedCatchupProgram = useSetLastWatchedCatchupProgram()
  return () => {
    setLastWatchedCatchupProgram()
    useExitFromApp()
  }
}
