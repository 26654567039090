import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { sha256 } from 'js-sha256'
import FormData from 'form-data'
import md5 from 'md5'

import { createApi } from '@reduxjs/toolkit/query/react'

import {
  IAuthInitialState,
  TAuthType,
  TAuthSteps,
  IAuthMiddlewareResponse,
  TAuthInputSubmitBackPositions,
  TAuthInputSubmitPositions,
  IAuthMiddlewareRequest,
  IAuthInRequest,
  IAuthCodeResponse,
  IAuthCodeRequest,
  IStep2Data,
  ISuccess,
  IAuthActivationCodeResponse,
  IAuthCustomerTokenResponse,
  IAuthCustomerTokenRequest,
  IAuthInResponse,
  IAuthProfilePageResponse,
  IAuthUserInfoResponse,
  TError,
  IGetBindDeviceByQrDataResponse,
  ICheckAuthByQrResponse,
  ICheckAuthByQrRequest,
  IGetBindDeviceByQrDataTransformResponse,
  IAuthInHashRequest,
  IIttvClientAuthResponse,
  IIttvClientAuthRequest,
} from '@assets/types/auth.types'

import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { AppState } from '@store/store'
import { playerApi } from '@store/slices/player.slice'
import { dialogsActions } from '@store/slices/dialogs.slice'
import { overlaysActions } from '@store/slices/overlays.slice'
import { appApi } from '@store/slices/app.slice'

import {
  ITTV_ID,
  OMEGATV_MAC_ADDRESS,
  OMEGATV_MODEL_NAME,
  OMEGATV_SERIAL_NUMBER,
  SESSION_TOKEN,
  USER_PHONE,
  USER_TOKEN,
} from '@utils/vars'
import { getTimeNow } from '@utils/getTimeNow'
import { deleteUserDataFromlocalStorage } from '@utils/auth/deleteUserDataFromLocalStorage'
import { useGetItemFromLocalStorage } from '@hooks/app/localStorage/useGetItemFromLocalStorage'

const initialState: IAuthInitialState = {
  isAuthenticated: false,
  isB2bPromo: false,
  invalidate: false,
  step: 'Step1',
  authSuccessData: null,
  authErrorData: null,
  authByQr: {
    isActive: false,
    isRegenerateQr: false,
  },
  authType: {
    isActive: true,
    type: 'Phone',
  },
  b2cByCode: {
    isActive: false,
    position: 'input',
  },
  b2cStep1: {
    isActive: false,
    position: 'input',
  },
  b2cStep2: {
    timerCounting: true,
    isActive: false,
    position: 'input',
    data: {
      phone: '',
      tempid: '',
    },
  },
  b2bByCode: {
    isFocused: false,
    activationCode: {
      value: '',
      isActive: true,
      isFocused: false,
    },
    submit: {
      isActive: false,
    },
    promo: {
      isActive: false,
    },
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    auth_reset: () => initialState,

    // set isPromo
    auth_setIsB2bPromo: (state, action: PayloadAction<boolean>) => {
      state.isB2bPromo = action.payload
    },

    // auth by qr
    auth_authByQr_setIsActive: (state, action: PayloadAction<boolean>) => {
      state.authByQr.isActive = action.payload
    },
    auth_authByQr_setRegenerateQr: (state, action: PayloadAction<boolean>) => {
      state.authByQr.isRegenerateQr = action.payload
    },

    auth_setInvalidate: (state, action: PayloadAction<boolean>) => {
      state.invalidate = action.payload
    },
    // set success middleware data
    auth_setSuccessMiddlewareAuthData: (
      state,
      action: PayloadAction<ISuccess>
    ) => {
      state.authSuccessData = action.payload
    },

    // set error middleware data
    auth_setErrorMiddlewareAuthData: (state, action: PayloadAction<TError>) => {
      state.authErrorData = action.payload
    },

    // Set authenticated
    auth_setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },

    // Change step
    auth_changeStep: (state, action: PayloadAction<TAuthSteps>) => {
      state.step = action.payload
    },

    // Auth type
    auth_authType_setAuthTypeIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.authType.isActive = action.payload
    },
    auth_authType_changeAuthType: (state, action: PayloadAction<TAuthType>) => {
      state.authType.type = action.payload
    },

    // B2c Step 1
    auth_b2cStep1_resetB2cStep1: (state) => {
      state.b2cStep1 = initialState.b2cStep1
    },
    auth_b2cStep1_setB2cStep1IsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2cStep1.isActive = action.payload
    },
    auth_b2cStep1_changeB2cStep1Position: (
      state,
      action: PayloadAction<TAuthInputSubmitPositions>
    ) => {
      state.b2cStep1.position = action.payload
    },

    // B2c Step 2
    auth_b2cStep2_resetB2cStep2: (state) => {
      state.b2cStep2 = initialState.b2cStep2
    },
    auth_b2cStep2_setB2cStep2Data: (
      state,
      action: PayloadAction<IStep2Data>
    ) => {
      state.b2cStep2.data = action.payload
    },
    auth_b2cStep2_setB2cStep2IsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2cStep2.isActive = action.payload
    },
    auth_b2cStep2_changeB2cStep2Position: (
      state,
      action: PayloadAction<TAuthInputSubmitBackPositions>
    ) => {
      state.b2cStep2.position = action.payload
    },
    auth_b2cStep2_changeStatusTimer: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2cStep2.timerCounting = action.payload
    },

    // B2c activate device by code
    auth_b2cByCode_resetB2cByCode: (state) => {
      state.b2cByCode = initialState.b2cByCode
    },
    auth_b2cByCode_setB2cByCodeIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2cByCode.isActive = action.payload
    },
    auth_b2cByCode_changeB2cByCodePosition: (
      state,
      action: PayloadAction<TAuthInputSubmitPositions>
    ) => {
      state.b2cByCode.position = action.payload
    },

    // B2b activate device by code
    auth_b2bByCode_resetB2bByCode: (state) => {
      state.b2bByCode = initialState.b2bByCode
    },
    auth_b2bByCode_activationCode_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2bByCode.activationCode.isActive = action.payload
    },
    auth_b2bByCode_activationCode_setValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.b2bByCode.activationCode.value = action.payload
    },
    auth_b2bByCode_activationCode_click: (state) => {
      if (!state.b2bByCode.activationCode.isFocused)
        state.b2bByCode.activationCode.isFocused = true
      state.b2bByCode.activationCode.isActive = true
      state.b2bByCode.promo.isActive = false
      state.b2bByCode.submit.isActive = false
    },
    auth_b2bByCode_promo_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2bByCode.promo.isActive = action.payload
    },
    auth_b2bByCode_promo_click: (state) => {
      state.b2bByCode.promo.isActive = true
      state.b2bByCode.activationCode.isActive = false
      state.b2bByCode.submit.isActive = false
    },
    auth_b2bByCode_submit_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.b2bByCode.submit.isActive = action.payload
    },
    auth_b2bByCode_submit_click: (state) => {
      state.b2bByCode.submit.isActive = true
      state.b2bByCode.promo.isActive = false
      state.b2bByCode.activationCode.isActive = false
    },
  },
})

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_API_URL,
  }),
  tagTypes: ['user_info', 'profile_page'],
  endpoints: (builder) => ({
    // https://cdnua01.hls.tv/v3/hlsclient/auth
    get_middleware_auth_data: builder.mutation<
      IAuthMiddlewareResponse,
      IAuthMiddlewareRequest | void
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const authCredentials = new FormData()
        const customizationCompanyId = appState().app.customizationCompanyId

        if (
          __PLATFORM__ !== 'desktop' ||
          (__PLATFORM__ === 'desktop' && __MODE__ === 'development')
        ) {
          authCredentials.append(
            'sn',
            localStorage.getItem(OMEGATV_MAC_ADDRESS)
          )
          authCredentials.append(
            'model',
            localStorage.getItem(OMEGATV_MODEL_NAME)
          )
          authCredentials.append('info', `${__PLATFORM__}Device`)
          authCredentials.append('version', process.env.APP_VERSION)
          authCredentials.append(
            'platform',
            `${__PLATFORM__ === 'desktop' ? 'webclient' : __PLATFORM__}`
          )

          if (useGetItemFromLocalStorage(SESSION_TOKEN)) {
            const sessionToken = useGetItemFromLocalStorage(SESSION_TOKEN)
            authCredentials.append('token', sessionToken)
          }

          if (localStorage.getItem(OMEGATV_SERIAL_NUMBER)) {
            authCredentials.append(
              'hash',
              md5(
                `${localStorage.getItem(
                  OMEGATV_MAC_ADDRESS
                )}${localStorage.getItem(OMEGATV_MODEL_NAME)}`
              )
            )
          } else {
            authCredentials.append(
              'hash',
              md5(`${localStorage.getItem(OMEGATV_MAC_ADDRESS)}`)
            )
          }
          if (customizationCompanyId)
            authCredentials.append('company_id', customizationCompanyId)

          if (_args?.code) authCredentials.append('code', _args.code)
        }

        try {
          const desktopIsProd =
            __PLATFORM__ === 'desktop' && __MODE__ === 'production'
          const authUrl = `${process.env.BASE_CDN_URL}/v3/hlsclient/auth`

          const url = desktopIsProd
            ? authUrl
            : _args?.cdnUrl
              ? _args?.cdnUrl
              : authUrl

          const { data } = await axios.post<IAuthMiddlewareResponse>(
            url,
            desktopIsProd ? undefined : authCredentials,
            {
              withCredentials: desktopIsProd ? true : undefined,
            }
          )
      
          if (data && data.error) {
            // save session token
            if (data.token) localStorage.setItem(SESSION_TOKEN, data.token)
            _api.dispatch(authActions.auth_setErrorMiddlewareAuthData(data))
            _api.dispatch(
              dialogsActions.dialogs_error_setErrorCode(data.error.toString())
            )
            return { data }
          }

          if (data && data.success === 'WRONGSTBDATAUNIQ') {
            if (data.token) localStorage.setItem(SESSION_TOKEN, data.token)
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode('7100'))
            return { data }
          }

          // clear user data (token/phone number)
          if (
            data &&
            typeof data.success === 'object' &&
            data.success.message.new
          ) {
            if (data.success.token) {
              localStorage.setItem(SESSION_TOKEN, data.success.token)
            }
            deleteUserDataFromlocalStorage()
          }

          if (
            data &&
            typeof data.success === 'object' &&
            data.success?.message.b2c &&
            data.success.trashed?.type === 'paused'
          ) {
            if (data.success.token) {
              localStorage.setItem(SESSION_TOKEN, data.success.token)
            }
            _api.dispatch(
              authActions.auth_setSuccessMiddlewareAuthData(data.success)
            )
            return { data }
          }

          if (data && data.success && typeof data.success === 'object') {
            if (data.success.token) {
              localStorage.setItem(SESSION_TOKEN, data.success.token)
            }
            _api.dispatch(
              authActions.auth_setSuccessMiddlewareAuthData(data.success)
            )

            // ITTV custom auth
            const newClient = data.success.message.new
            if (customizationCompanyId === ITTV_ID && newClient) {
              const uniq = appState().auth.authSuccessData?.uniq
              if (uniq)
                await _api.dispatch(
                  authApi.endpoints.ittv_client_auth.initiate({ uniq })
                )
            }

            // b2b activate device
            if (!data.success.message.b2c && !data.success.trashed) {
              await _api.dispatch(
                playerApi.endpoints.get_categories.initiate({
                  playlist_hash: appState().auth.authSuccessData
                    ?.playlist as string,
                  promo: false,
                })
              )
              _api.dispatch(authActions.auth_b2bByCode_resetB2bByCode())
            }

            // b2c activate device
            if (data.success.message.b2c && !data.success.message.new) {
              const userToken = localStorage.getItem(USER_TOKEN)
              const userPhone = localStorage.getItem(USER_PHONE)

              if (userToken && userPhone) {
                const { data: userInfoData } = await _api.dispatch(
                  authApi.endpoints.user_info.initiate()
                )

                // show dialog if error
                if (!userInfoData) return { data }
                if (
                  userInfoData &&
                  userInfoData.code !== '200' &&
                  userInfoData.code !== '4011'
                ) {
                  return { data }
                }

                const { data: profilePageData } = await _api.dispatch(
                  authApi.endpoints.profile_page.initiate()
                )

                // show dialog if error
                if (!profilePageData) return { data }
                if (
                  profilePageData &&
                  profilePageData.code !== '200' &&
                  profilePageData.code !== '4011'
                ) {
                  return { data }
                }

                await _api.dispatch(
                  playerApi.endpoints.get_categories.initiate({
                    playlist_hash: appState().auth.authSuccessData
                      ?.playlist as string,
                    promo: false,
                  })
                )
              } else {
                if (data.success.message.customer_token)
                  await _api.dispatch(
                    authApi.endpoints.auth_customer_token.initiate({
                      customer_token: data.success.message.customer_token,
                    })
                  )
              }
              if (appState().auth.invalidate) {
                await _api.dispatch(authApi.util.invalidateTags(['user_info']))
                await _api.dispatch(
                  authApi.util.invalidateTags(['profile_page'])
                )
              }
            }
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/auth/in
    auth_in: builder.mutation<IAuthInResponse, IAuthInRequest>({
      queryFn: async (_args, _api) => {
        const request = new FormData()

        request.append('phone', `38${_args.phone}`)
        request.append('retoken', _args.retoken)

        try {
          const url = `${process.env.BASE_API_URL}/auth/in`
          const { data } = await axios.post<IAuthInResponse>(url, request)

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          if (data && data.code === '200' && data.response?.tempid) {
            _api.dispatch(
              authActions.auth_b2cStep2_setB2cStep2Data({
                phone: _args.phone,
                tempid: data.response.tempid,
              })
            )
            _api.dispatch(authActions.auth_changeStep('Step2'))
            _api.dispatch(authActions.auth_b2cStep2_setB2cStep2IsActive(true))
            _api.dispatch(authActions.auth_b2cStep1_resetB2cStep1())
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/auth/in_hash
    auth_in_hash: builder.mutation<IAuthInResponse, IAuthInHashRequest>({
      queryFn: async (_args, _api) => {
        const request = new FormData()

        const hash = sha256(`38${_args.phone}j834R4FN834H8cr94Bc93`)

        request.append('phone', `38${_args.phone}`)
        request.append('hash', hash)

        try {
          const url = `${process.env.BASE_API_URL}/auth/in_hash`
          const { data } = await axios.post<IAuthInResponse>(url, request)

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          if (data && data.code === '200' && data.response?.tempid) {
            _api.dispatch(
              authActions.auth_b2cStep2_setB2cStep2Data({
                phone: _args.phone,
                tempid: data.response.tempid,
              })
            )
            _api.dispatch(authActions.auth_changeStep('Step2'))
            _api.dispatch(authActions.auth_b2cStep2_setB2cStep2IsActive(true))
            _api.dispatch(authActions.auth_b2cStep1_resetB2cStep1())
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/auth/checkin
    // auth_checkin: builder.mutation<IAuthCheckinResponse, void>({
    //   queryFn: async (_args, _api) => {
    //     const request = new FormData()
    //     const userPhone = localStorage.getItem(USER_PHONE)

    //     request.append('phone', `38${userPhone}`)

    //     try {
    //       const url = `${process.env.BASE_API_URL}/auth/checkin`
    //       const { data } = await axios.post<IAuthCheckinResponse>(url, request)

    //       if (data && data.code !== '200') {
    //         _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
    //         return { data }
    //       }

    //       if (data && data.code === '200' && data.response?.code && userPhone) {
    //         _api.dispatch(
    //           authActions.auth_b2cStep2_setB2cStep2Data({
    //             phone: userPhone,
    //             tempid: data.response.tempid,
    //           })
    //         )
    //         await _api.dispatch(
    //           authApi.endpoints.auth_code.initiate({
    //             code: data.response.code,
    //             refresh: true,
    //           })
    //         )
    //       }

    //       return { data }
    //     } catch (error) {
    //       const { request } = error as AxiosResponse
    //       const { status, statusText } = request as IError
    //       _api.dispatch(
    //         dialogsActions.dialogs_error_setErrorCode(
    //           status ? status.toString() : '500'
    //         )
    //       )
    //       return {
    //         error: {
    //           status,
    //           statusText,
    //         },
    //       }
    //     }
    //   },
    // }),

    // https://api.omegatv.ua/auth/code
    auth_code: builder.mutation<IAuthCodeResponse, IAuthCodeRequest>({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const tempid = appState().auth.b2cStep2.data.tempid
        const phone = appState().auth.b2cStep2.data.phone

        const request = new FormData()

        request.append('code', _args.code)
        request.append('tempid', tempid)

        try {
          const url = `${process.env.BASE_API_URL}/auth/code`
          const { data } = await axios.post<IAuthCodeResponse>(url, request)

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          if (data.response && data.code === '200') {
            const token = data.response.token
            localStorage.setItem(USER_TOKEN, token)
            localStorage.setItem(USER_PHONE, phone)

            if (_args.refresh) {
              _api.dispatch(authActions.auth_setInvalidate(true))
            } else {
              const { data: userInfoData } = await _api.dispatch(
                authApi.endpoints.user_info.initiate()
              )
              await _api.dispatch(authApi.endpoints.profile_page.initiate())

              // check if user has max connected devices on account
              if (
                userInfoData?.response?.tarif_info_user.count_dev_reg ===
                userInfoData?.response?.tarif_info_user.count_dev_max
              ) {
                _api.dispatch(
                  overlaysActions.overlays_deviceManager_setisOpened(true)
                )
                _api.dispatch(
                  authActions.auth_b2cStep2_setB2cStep2IsActive(false)
                )
                return { data }
              }

              await _api.dispatch(
                authApi.endpoints.auth_activation_code.initiate()
              )
            }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/device/activation_code
    auth_activation_code: builder.mutation<IAuthActivationCodeResponse, void>({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState

        const request = new FormData()
        const token = localStorage.getItem(USER_TOKEN)

        request.append('token', token)

        try {
          const url = `${process.env.BASE_API_URL}/device/activation_code`
          const { data } = await axios.post<IAuthActivationCodeResponse>(
            url,
            request
          )

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          if (data.response && data.code === '200') {
            if (!appState().overlays.deviceManager.isOpened)
              _api.dispatch(authActions.auth_setInvalidate(true))

            if (appState().overlays.deviceManager.isOpened) {
              _api.dispatch(
                overlaysActions.overlays_deviceManager_setIsInvalidateUserData(
                  true
                )
              )
            }

            await _api.dispatch(
              authApi.endpoints.get_middleware_auth_data.initiate({
                code: data.response.code,
              })
            )
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status: status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/auth/customer_token
    auth_customer_token: builder.mutation<
      IAuthCustomerTokenResponse,
      IAuthCustomerTokenRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const request = new FormData()
        request.append('customer_token', _args.customer_token)

        try {
          const url = `${process.env.BASE_API_URL}/auth/customer_token`
          const { data } = await axios.post<IAuthCustomerTokenResponse>(
            url,
            request
          )

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          if (data.response && data.code === '200') {
            localStorage.setItem(USER_TOKEN, data.response.billing_token)
            localStorage.setItem(USER_PHONE, data.response.phone.slice(2))

            const { data: userInfoData } = await _api.dispatch(
              authApi.endpoints.user_info.initiate()
            )

            // show dialog if error
            if (!userInfoData) return { data }
            if (
              userInfoData &&
              userInfoData.code !== '200' &&
              userInfoData.code !== '4011'
            ) {
              return { data }
            }

            const { data: profilePageData } = await _api.dispatch(
              authApi.endpoints.profile_page.initiate()
            )

            // show dialog if error
            if (!profilePageData) return { data }
            if (
              profilePageData &&
              profilePageData.code !== '200' &&
              profilePageData.code !== '4011'
            ) {
              return { data }
            }

            await _api.dispatch(
              playerApi.endpoints.get_categories.initiate({
                playlist_hash: appState().auth.authSuccessData
                  ?.playlist as string,
                promo: false,
              })
            )
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/profile_page
    profile_page: builder.query<IAuthProfilePageResponse, void>({
      queryFn: async (_, _api) => {
        const token = localStorage.getItem(USER_TOKEN)

        const request = new FormData()
        request.append('token', token)

        try {
          const url = `${process.env.BASE_API_URL}/profile_page`
          const { data } = await axios.post<IAuthProfilePageResponse>(
            url,
            request
          )

          if (data && data.code === '4011') {
            deleteUserDataFromlocalStorage()
            _api.dispatch(authApi.util.resetApiState())
            _api.dispatch(
              authApi.endpoints.get_middleware_auth_data.initiate(undefined, {
                fixedCacheKey: 'shared-auth',
              })
            )
            return { data }
          }

          if (data && data.code !== '200') {
            if (data.code !== '4011') {
              _api.dispatch(
                dialogsActions.dialogs_error_setErrorCode(data.code)
              )
            }
            return { data }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      providesTags: ['profile_page'],
    }),

    // https://api.omegatv.ua/user_info
    user_info: builder.query<IAuthUserInfoResponse, void>({
      queryFn: async (_, _api) => {
        const token = localStorage.getItem(USER_TOKEN)

        const request = new FormData()
        request.append('token', token)

        try {
          const url = `${process.env.BASE_API_URL}/user_info`
          const { data } = await axios.post<IAuthUserInfoResponse>(url, request)

          if (data && data.code === '4011') {
            deleteUserDataFromlocalStorage()
            _api.dispatch(authApi.util.resetApiState())
            _api.dispatch(
              authApi.endpoints.get_middleware_auth_data.initiate(undefined, {
                fixedCacheKey: 'shared-auth',
              })
            )
            return { data }
          }

          if (data && data.code !== '200') {
            if (data.code !== '4011') {
              _api.dispatch(
                dialogsActions.dialogs_error_setErrorCode(data.code)
              )
            }
            return { data }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      providesTags: ['user_info'],
    }),

    // https://auth.hls.tv/v3/hlsclient/qr/get
    get_bind_device_by_qr_data: builder.query<
      IGetBindDeviceByQrDataTransformResponse,
      void
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState() as AppState
        const authErrorData = appState.auth.authErrorData
        const successToken = appState.auth.authSuccessData?.token
        const errorToken = appState.auth.authErrorData?.token
        const isRegenerateQr = appState.auth.authByQr.isRegenerateQr
        const isActive = appState.auth.authByQr.isActive
        const step = appState.auth.step

        const request = new FormData()
        request.append('token', authErrorData ? errorToken : successToken)

        try {
          const url = 'https://auth.hls.tv/v3/hlsclient/qr/get'
          const { data: bindDeviceByQrData } =
            await axios.post<IGetBindDeviceByQrDataResponse>(url, request)

          // set focus
          if (isActive) {
            _api.dispatch(authActions.auth_authByQr_setIsActive(false))
            if (step === 'Step1') {
              _api.dispatch(authActions.auth_authType_setAuthTypeIsActive(true))
            } else {
              _api.dispatch(authActions.auth_b2cStep2_setB2cStep2IsActive(true))
            }
          }

          if (bindDeviceByQrData && bindDeviceByQrData.status === 'error') {
            return {
              error: {
                status: bindDeviceByQrData.code,
                statusText: bindDeviceByQrData.message,
              },
            }
          }

          const generateQrUrl = `https://link.omegatv.ua/?apn=tv.hls.omegatv.box&amv=2430000&link=https://omegatv.ua/device/uniq/${bindDeviceByQrData.result?.qr_code}`
          const { data: generateQrData } = await _api.dispatch(
            appApi.endpoints.generate_qr.initiate({ qr_string: generateQrUrl })
          )

          if (generateQrData && generateQrData.code !== '200') {
            return {
              error: {
                status: +generateQrData.code,
                statusText: generateQrData.msg,
              },
            }
          }

          if (isRegenerateQr)
            _api.dispatch(authActions.auth_authByQr_setRegenerateQr(false))

          return {
            data: {
              ...bindDeviceByQrData,
              qr: generateQrData?.response?.qr,
            },
          }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          // set focus
          if (isActive) {
            _api.dispatch(authActions.auth_authByQr_setIsActive(false))
            if (step === 'Step1') {
              _api.dispatch(authActions.auth_authType_setAuthTypeIsActive(true))
            } else {
              _api.dispatch(authActions.auth_b2cStep2_setB2cStep2IsActive(true))
            }
          }
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    // https://auth.hls.tv/v3/hlsclient/qr/wait_result
    check_auth_by_qr: builder.query<
      ICheckAuthByQrResponse,
      ICheckAuthByQrRequest
    >({
      queryFn: async (_args, _api) => {
        try {
          const { data } = await axios.get<ICheckAuthByQrResponse>(_args.url)

          if (_args.timeStamp && _args.timeout) {
            const timeNow = getTimeNow()
            if (timeNow > _args.timeStamp + _args.timeout) {
              _api.dispatch(authActions.auth_authByQr_setRegenerateQr(true))
            }
          }

          if (data.status === 'error') {
            _api.dispatch(authActions.auth_authByQr_setRegenerateQr(true))
          }

          if (typeof data.result === 'object') {
            if (data.status === 'ok' && data.result.success) {
              const appState = _api.getState() as AppState
              const isRegenerateQr = appState.auth.authByQr.isRegenerateQr

              if (isRegenerateQr) return { data }
              _api.dispatch(authApi.util.resetApiState())
              _api.dispatch(
                authApi.endpoints.get_middleware_auth_data.initiate(undefined, {
                  fixedCacheKey: 'shared-auth',
                })
              )
            }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    // Custom auth (ITTV)
    // https://bms.it-tv.org/stat/omega-tv.php
    ittv_client_auth: builder.mutation<
      IIttvClientAuthResponse,
      IIttvClientAuthRequest
    >({
      queryFn: async (_args, _api) => {
        const url = 'https://bms.it-tv.org/stat/omega-tv.php'
        const formData = new FormData()

        formData.append('uniq', _args.uniq)

        try {
          const { data } = await axios.post<IIttvClientAuthResponse>(
            url,
            formData
          )

          if (data && data.result === 'success') {
            _api.dispatch(authActions.auth_reset())
            _api.dispatch(authApi.util.resetApiState())
            _api.dispatch(
              authApi.endpoints.get_middleware_auth_data.initiate(undefined, {
                fixedCacheKey: 'shared-auth',
              })
            )
            return { data }
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const authReducer = authSlice.reducer
export const authActions = authSlice.actions

export const {
  useGet_middleware_auth_dataMutation,
  useAuth_inMutation,
  useAuth_in_hashMutation,
  useAuth_codeMutation,
  useUser_infoQuery,
  useAuth_activation_codeMutation,
  useGet_bind_device_by_qr_dataQuery,
  useCheck_auth_by_qrQuery,
} = authApi
