import { useLayoutEffect } from 'react'
import { useGetCompanyData } from './useGetCompanyData'

export const useSetTitle = (loaded: boolean) => {
  const { companyName } = useGetCompanyData()

  useLayoutEffect(() => {
    document.title = companyName
    if (__PLATFORM__ !== 'desktop') document.body.style.overflow = 'hidden'
  }, [loaded])
}
