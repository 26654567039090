import React, { FC } from 'react'

import { Loader } from '@components/UIElements/Loader/Loader'

import styles from '@components/Overlays/FullScreenLoader/full.screen.loader.module.scss'

interface IFullScreenLoaderProps {
  loaderSize: number
}

export const FullScreenLoader: FC<IFullScreenLoaderProps> = ({
  loaderSize,
}) => {
  return (
    <div className={styles.fullScreenLoader}>
      <div className={styles.fullScreenLoaderWrapper}>
        <Loader size={loaderSize} fullScreen />
      </div>
    </div>
  )
}
