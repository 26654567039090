import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { EditDevice } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'edit-device'*/ '@components/Overlays/EditDevice/EditDevice'
    ),
  'EditDevice'
)

export const LazyEditDevice: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.overlays.editDevice.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <EditDevice />}</Suspense>
}
