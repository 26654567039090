import axios, { AxiosResponse } from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import { AppState } from '@store/store'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import {
  IGetFilmDetailsResponse,
  IMoviesFilmDetailsInitialState,
  Position,
  TEpisode,
  TRelated,
} from '@assets/types/movies/filmDetails.types'
import { TLanguage } from '@assets/types/app.types'
import { IChannel } from '@assets/types/player.types'
import { dialogsActions } from '../dialogs.slice'

const initialState: IMoviesFilmDetailsInitialState = {
  poster: {
    isActive: false,
    position: 'play',
  },
  description: {
    isShowMore: false,
    isActive: false,
    isExpanded: false,
  },
  gallery: {
    isActive: false,
    activeImageIdx: 0,
    currentBackgroundImage: 0,
  },
  episodes: {
    season: null,
    isActive: false,
    activeEpisodeIdx: 0,
    selectedEpisodeIdx: 0,
    items: null,
  },
  related: {
    isActive: false,
    activeRelatedItemIdx: 0,
  },
}

export const moviesFilmDetailsSlice = createSlice({
  name: 'moviesFilmDetails',
  initialState,
  reducers: {
    movies_filmDetails_reset: () => initialState,

    // Poster section
    movies_filmDetails_poster_setPosterIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.poster.isActive = action.payload
    },
    movies_filmDetails_poster_setPosterPosition: (
      state,
      action: PayloadAction<Position>
    ) => {
      state.poster.position = action.payload
    },
    movies_filDetails_poster_clickPlayBtn: (state) => {
      state.poster.isActive = true
      state.poster.position = 'play'
      // Reset gallery section
      state.gallery.isActive = false
      // Reset episodes section
      state.episodes.isActive = false
      // Set description state section
      state.description.isActive = false
      // Reset related section
      state.related.isActive = false
      state.related.activeRelatedItemIdx = 0
    },

    // Description section
    movies_filmDetails_description_setDescriptionIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.description.isActive = action.payload
    },
    movies_filmDetails_description_setDescriptionIsExpanded: (state) => {
      state.description.isExpanded = !state.description.isExpanded
    },
    movies_filmDetails_description_setIsShowMore: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.description.isShowMore = action.payload
    },
    movies_filmDetails_description_clickExpanded: (state) => {
      // Reset poster section
      state.poster.isActive = false
      state.poster.position = 'play'
      // Reset gallery section
      state.gallery.isActive = false
      // Reset episodes section
      state.episodes.isActive = false
      // Set description state section
      state.description.isActive = true
      state.description.isExpanded = !state.description.isExpanded
      // Reset related section
      state.related.isActive = false
      state.related.activeRelatedItemIdx = 0
    },

    // Gallery section
    movies_filmDetails_gallery_setGalleryIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.gallery.isActive = action.payload
    },
    movies_filmDetails_gallery_incrementActiveImageIdx: (state) => {
      state.gallery.activeImageIdx++
    },
    movies_filmDetails_gallery_decrementActiveImageIdx: (state) => {
      state.gallery.activeImageIdx--
    },
    movies_filmDetails_gallery_setActiveImageIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.gallery.activeImageIdx = action.payload
      state.gallery.currentBackgroundImage = action.payload
    },
    movies_filmDetails_gallery_setBackgroundImage: (state) => {
      state.gallery.currentBackgroundImage = state.gallery.activeImageIdx
    },
    movies_filmDetails_gallery_clickImageItem: (
      state,
      action: PayloadAction<number>
    ) => {
      // Reset poster section
      state.poster.isActive = false
      state.poster.position = 'play'
      // Reset episodes section
      state.episodes.isActive = false
      // Set description state section
      state.description.isActive = false
      // Reset related section
      state.related.isActive = false
      state.related.activeRelatedItemIdx = 0
      // Set gallery state
      state.gallery.isActive = true
      state.gallery.activeImageIdx = action.payload
      state.gallery.currentBackgroundImage = action.payload
    },

    // Episodes section
    movies_filmDetails_episodes_setEpisodesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.episodes.isActive = action.payload
    },
    movies_filmDetails_episodes_incrementActiveEpisodeIdx: (state) => {
      state.episodes.activeEpisodeIdx++
    },
    movies_filmDetails_episodes_decrementActiveEpisodeIdx: (state) => {
      state.episodes.activeEpisodeIdx--
    },
    movies_filmDetails_episodes_setActiveEpisodeIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.episodes.activeEpisodeIdx = action.payload
    },
    movies_filmDetails_episodes_setSelectedEpisodeIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.episodes.selectedEpisodeIdx = action.payload
      state.episodes.activeEpisodeIdx = state.episodes.selectedEpisodeIdx
      // Reset poster section
      state.poster.isActive = false
      state.poster.position = 'play'
      // Set description state section
      state.description.isActive = false
      // Reset related section
      state.related.isActive = false
      state.related.activeRelatedItemIdx = 0
      // Set gallery state
      state.gallery.isActive = false
    },
    movies_filmDetails_episodes_incrementSelectedEpisodeIdx: (state) => {
      state.episodes.selectedEpisodeIdx++
      state.episodes.activeEpisodeIdx = state.episodes.selectedEpisodeIdx
    },
    movies_filmDetails_episodes_decrementSelectedEpisodeIdx: (state) => {
      state.episodes.selectedEpisodeIdx--
      state.episodes.activeEpisodeIdx = state.episodes.selectedEpisodeIdx
    },
    movies_filmDetails_episodes_setItems: (
      state,
      action: PayloadAction<TEpisode[]>
    ) => {
      state.episodes.items = action.payload
    },
    movies_filmDetails_episodes_setSeason: (
      state,
      action: PayloadAction<string>
    ) => {
      state.episodes.season = action.payload
    },

    // Related section
    movies_filmDetails_related_setRelatedIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.related.isActive = action.payload
    },
    movies_filmDetails_related_incrementActiveRelatedItemIdx: (state) => {
      state.related.activeRelatedItemIdx++
    },
    movies_filmDetails_related_decrementActiveRelatedItemIdx: (state) => {
      state.related.activeRelatedItemIdx--
    },
    movies_filmDetails_related_setActiveRelatedItemIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.related.activeRelatedItemIdx = action.payload
    },
  },
})

export const moviesFilmDetailsApi = createApi({
  reducerPath: 'moviesFilmDetailsApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),

  endpoints: (builder) => ({
    get_movie_details: builder.query<
      IGetFilmDetailsResponse,
      { film_id: string; language: TLanguage }
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_CDN_URL}/v1/catalog/${_args.film_id}/full_info.json&lang=${_args.language}`

        try {
          const { data } = await axios.get<IGetFilmDetailsResponse>(url)
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    get_episodes: builder.query<TEpisode[], { episodesUrl: string }>({
      queryFn: async (_args, _api) => {
        const getState = _api.getState as () => AppState
        try {
          const userChannels = getState().player.categories.items[0]
            .channels as IChannel[]
          const { data } = await axios.get<TEpisode[]>(_args.episodesUrl)
          // Filtering items by user's tariff
          const filteredEpisodes = data.filter((item) =>
            userChannels.some((channel) => channel.id === item.channel.id)
          )

          return { data: filteredEpisodes }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    get_related_items: builder.query<
      TRelated[],
      { relatedUrl: string; currentMovieId: number }
    >({
      queryFn: async (_args, _api) => {
        const getState = _api.getState as () => AppState
        try {
          const userChannels = getState().player.categories.items[0]
            .channels as IChannel[]
          const { data } = await axios.get<TRelated[]>(_args.relatedUrl)
          // Filtering items by user's tariff
          const filteredRelatedItems = data.filter((item) =>
            userChannels.some(
              (channel) =>
                channel.id === item.channel.id &&
                item.id !== _args.currentMovieId
            )
          )

          return { data: filteredRelatedItems }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const moviesFilmDetailsReducer = moviesFilmDetailsSlice.reducer
export const moviesFilmDetailsActions = moviesFilmDetailsSlice.actions

export const {
  useGet_movie_detailsQuery,
  useGet_episodesQuery,
  useGet_related_itemsQuery,
} = moviesFilmDetailsApi
