import { useTypedAction } from '@hooks/common/useTypedActions'
import { useTypedSelector } from '@hooks/common/useTypedSelector'

export const useSetLastWatchedCatchupProgram = (): (() => void) => {
  const isLive = useTypedSelector((state) => state.player.hlsPlayer.isLive)
  const { app_setLastWatchedCatchupProgram } = useTypedAction()

  return () => {
    if (isLive) return
    app_setLastWatchedCatchupProgram()
  }
}
