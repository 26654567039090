import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ConfirmChangePlan } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'confirm-change-plan'*/ '@components/Dialogs/ConfirmChangePlan/ConfirmChangePlan'
    ),
  'ConfirmChangePlan'
)

export const LazyConfirmChangePlan: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.changePlan.isOpened
  )

  return (
    <Suspense fallback={null}>{isOpened && <ConfirmChangePlan />}</Suspense>
  )
}
