import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ActivateVoucher } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'voucher'*/ '@components/Overlays/ActivateVoucher/ActivateVoucher'
    ),
  'ActivateVoucher'
)

export const LazyVoucher: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.overlays.activateVoucher.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <ActivateVoucher />}</Suspense>
}
