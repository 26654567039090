import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { DeviceManager } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'device-manager'*/ '@components/Overlays/DeviceManager/DeviceManager'
    ),
  'DeviceManager'
)

export const LazyDeviceManager: FC = () => {
  const isOpened = useTypedSelector((state) => state.overlays.deviceManager.isOpened)

  return <Suspense fallback={null}>{isOpened && <DeviceManager />}</Suspense>
}
