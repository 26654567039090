import { MouseEvent, useCallback } from 'react'

import btnStyles from '@components/UIElements/Button/button.module.scss'

export const mouseLeaveElement = (
  element: HTMLElement,
  hoverClass: string,
  callBack?: () => void
) => {
  element.removeAttribute('data-hover')
  element.classList.remove(hoverClass)
  const items = element.querySelectorAll('[data-animation]')

  if (items.length) {
    // use only for (not forEach es6) WebOS bug
    for (let i = 0; i < items.length; i++) {
      const isActive = !!items[i].getAttribute('data-active')
      if (isActive) return
      const animation = items[i].getAttribute('data-animation')
      if (animation) {
        items[i].removeAttribute('style')
      }
    }
  }
  if (callBack) callBack()
}

export const memoizedMouseLeaveElement = <T extends HTMLElement>(
  hoverClass: string | null,
  callBack?: () => void
) => {
  return useCallback((event: MouseEvent<T>) => {
    mouseLeaveElement(
      event.currentTarget,
      hoverClass ? hoverClass : btnStyles.svgBtnHover,
      callBack
    )
  }, [])
}
