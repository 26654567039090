import React, { FC } from 'react'
import KeyboardEventHandler from '@infinium/react-keyboard-event-handler'

import { useTypedAction } from '@hooks/common/useTypedActions'

import { KEY_EXIT_TIZEN } from '@utils/keyCodes'

export const AppActions: FC = () => {
  const { dialogs_confirm_exitApp_setIsOpened } = useTypedAction()

  return (
    <KeyboardEventHandler
      handleKeys={['all']}
      handleFocusableElements
      onKeyEvent={(_, event) => {
        const { keyCode } = event
        switch (keyCode) {
        case KEY_EXIT_TIZEN: {
          dialogs_confirm_exitApp_setIsOpened(true)
          break
        }
        default:
          return
        }
      }}
    />
  )
}
