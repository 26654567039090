import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  IMoviesInitialState,
  IRecentlyViewedEpisode,
} from '@assets/types/movies/movies.types'

import { useGetParsedItemFromLocalStorage } from '@hooks/app/localStorage/useGetParsedItemFromLocalStorage'

import { RECENTLY_VIEWED_EPISODES } from '@utils/vars'
import { getTimeNow } from '@utils/getTimeNow'

const initialState: IMoviesInitialState = {
  isMoviesMode: false,
  isShow: false,
  recentlyViewedEpisodes: null,
}

export const moviesSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    movies_setIsMoviesMode: (state, action: PayloadAction<boolean>) => {
      state.isMoviesMode = action.payload
    },
    movies_setIsShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload
    },

    // recently viewed
    movies_setRecentlyViewedEpisodes: (
      state,
      action: PayloadAction<IRecentlyViewedEpisode[]>
    ) => {
      state.recentlyViewedEpisodes = action.payload
    },
    movies_pushItemToRecentlyViewedEpisodes: (
      state,
      action: PayloadAction<IRecentlyViewedEpisode>
    ) => {
      state.recentlyViewedEpisodes?.push(action.payload)
    },
    movies_loadRecentlyViewedEpisodesFromLocalStorage: (state) => {
      const recentlyViewedEpisodes = useGetParsedItemFromLocalStorage<
        IRecentlyViewedEpisode[]
      >(RECENTLY_VIEWED_EPISODES)
      if (recentlyViewedEpisodes) {
        const filteredEpisodes = recentlyViewedEpisodes.filter(
          (episode) => getTimeNow() - episode.startAt < episode.ttl
        )
        if (filteredEpisodes.length) {
          state.recentlyViewedEpisodes = filteredEpisodes
        }
      }
    },
  },
})

export const moviesReducer = moviesSlice.reducer
export const moviesActions = moviesSlice.actions
