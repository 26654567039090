import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ReloadApp } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'reaload-app-dialog'*/ '@components/Dialogs/ReloadApp/ReloadApp'
    ),
  'ReloadApp'
)

export const LazyReloadApp: FC = () => {
  const isOpened = useTypedSelector((state) => state.dialogs.reloadApp.isOpened)

  if (!isOpened) return null

  return (
    <Suspense fallback={null}>
      <ReloadApp />
    </Suspense>
  )
}
