import { useEffect } from 'react'
import { useCloseApp } from '@hooks/app/useCloseApp'

export const useVivibilityChange = () => {
  // Fix for Tizen OS after power Off
  const closeApp = useCloseApp()

  useEffect(() => {
    if (__PLATFORM__ === 'tizen') {
      document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
          closeApp()
          return
        }
      })
    }

    return () => {
      if (__PLATFORM__ === 'tizen') {
        document.addEventListener('visibilitychange', function () {
          if (document.hidden) {
            closeApp()
            return
          }
        })
      }
    }
  }, [])
}
