import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { TCompanyIds } from '@assets/types/app.types'

import OmegatvLogo from '@assets/images/common/logo-with-text.svg'
import MaximumNetLogo from '@assets/images/customizations/maximum-logo.svg'
import BilinkLogo from '@assets/images/customizations/bilink-logo.svg'
import ITTVLogo from '@assets/images/customizations/ittv-logo.svg'

interface ICompanyData {
  appName: string
  companyName: string
  companyEmail: string
  logo: string
}

const companiesData: Record<TCompanyIds, ICompanyData> = {
  133: {
    appName: 'Maximum-Net OTTBox',
    companyName: 'ТОВ "Максимум-НЕТ"',
    companyEmail: 'office@maximuma.net',
    logo: MaximumNetLogo,
  },
  626: {
    appName: 'Bilink TV',
    companyName: 'ТОВ "Білінк"',
    companyEmail: 'info@bilink.ua',
    logo: BilinkLogo,
  },
  512: {
    appName: 'it-tv',
    companyName: 'ТОВ "Ультралінк"',
    companyEmail: 'feedback@it-tv.org',
    logo: ITTVLogo,
  },
}

export const useGetCompanyData = (): ICompanyData => {
  const customizationCompanyId = useTypedSelector(
    (state) => state.app.customizationCompanyId
  )

  if (customizationCompanyId && companiesData[customizationCompanyId])
    return companiesData[customizationCompanyId]

  return {
    appName: '',
    companyName: 'OmegaTV',
    companyEmail: '',
    logo: OmegatvLogo,
  }
}
