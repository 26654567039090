import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ConfirmExitMovies } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'confirm-exit-movies'*/ '@components/Dialogs/ConfirmExitMovies/ConfirmExitMovies'
    ),
  'ConfirmExitMovies'
)

export const LazyConfirmExitMovies: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.exitMovies.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <ConfirmExitMovies />}</Suspense>
}
