import { useEffect } from 'react'

import { useTypedAction } from '@hooks/common/useTypedActions'
import { useTypedSelector } from '@hooks/common/useTypedSelector'
import { useGetParsedItemFromLocalStorage } from '@hooks/app/localStorage/useGetParsedItemFromLocalStorage'
import { useGetChannelIsBlocked } from '@hooks/overlays/parentalControl/useGetChannelIsBlocked'

import { LAST_WATCHED_CATCHUP_PROGRAM_DATA } from '@utils/vars'

import { ILastWatchedCatchupProgramData } from '@assets/types/player.types'

export const useOpenContinueWatching = () => {
  const selectedChannelID = useTypedSelector(
    (state) => state.player.channels.selectedChannelID
  )
  const isLive = useTypedSelector((state) => state.player.hlsPlayer.isLive)
  const { dialogs_confirm_continueWatching_setIsOpened } = useTypedAction()

  const lastWatchedCatchupProgram =
    useGetParsedItemFromLocalStorage<ILastWatchedCatchupProgramData>(
      LAST_WATCHED_CATCHUP_PROGRAM_DATA
    )
  const isBlocked = useGetChannelIsBlocked()

  useEffect(() => {
    if (!isLive) return
    if (isBlocked) return
    const timeOut = setTimeout(() => {
      if (lastWatchedCatchupProgram) {
        if (selectedChannelID === lastWatchedCatchupProgram.channelId) {
          dialogs_confirm_continueWatching_setIsOpened(true)
        }
      }
    }, 2000)

    return () => clearTimeout(timeOut)
  }, [selectedChannelID, isBlocked])
}
