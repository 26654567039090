import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { t } from 'i18next'

import {
  IAllChannelsCurrentEpgResponse,
  ILeftMenuInitialState,
  ISetEpgDataFromPlayback,
} from '@assets/types/left-menu.types'
import {
  GetEpgByDayRequest,
  IGetEpgByDayResponse,
  IGetProgramDetailsResponse,
} from '@assets/types/common.types'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { dialogsActions } from '@store/slices/dialogs.slice'

const initialState: ILeftMenuInitialState = {
  isOpened: false,
  categories: {
    isActive: false,
    extra: {
      isActive: false,
    },
    main: {
      isActive: true,
    },
  },
  channels: {
    isActive: false,
  },
  epg: {
    isOpened: false,
    isActive: false,
    isFetching: false,
    isLoading: false,
    isKeyboardMode: true,
    epgLength: 0,
    payloadLength: 0,
    activeEpgIdx: 0,
    previousDay: -1,
    nextDay: 1,
    epgDay: 0,
    items: [],
    fetchedChannelId: null,
    fetchedChannelIdX: null
  },
  detail: {
    isOpened: false,
    fetchedEpgIdx: 0,
    watchBtn: {
      isActive: false,
    },
    gallery: {
      isActive: false,
      activeImageIdx: 0,
    },
    description: {
      isActive: false,
    },
  },
}

export const appLeftMenuSlice = createSlice({
  name: 'appLeftMenu',
  initialState,
  reducers: {
    appLeftMenu_reset: () => initialState,
    appLeftMenu_setLeftMenuIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpened = action.payload
    },

    // Channels
    appLeftMenu_channels_setChannelsIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.channels.isActive = action.payload
    },

    // Categories
    appLeftMenu_categories_setCategoriesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.categories.isActive = action.payload
    },

    // Main categories
    appLeftMenu_categories_main_setMainCategoriesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.categories.main.isActive = action.payload
    },

    // Extra categories
    appLeftMenu_categories_extra_setExtraCategoriesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.categories.extra.isActive = action.payload
    },

    // Epg
    appLeftMenu_epg_epgReset: (state) => {
      state.epg = initialState.epg
    },
    appLeftMenu_epg_epgPartialReset: (state) => {
      state.epg.previousDay = initialState.epg.previousDay
      state.epg.nextDay = initialState.epg.nextDay
      state.epg.epgDay = initialState.epg.epgDay
    },
    appLeftMenu_epg_setEpgIsOpened: (state, action: PayloadAction<boolean>) => {
      state.epg.isOpened = action.payload
    },
    appLeftMenu_epg_setEpgIsActive: (state, action: PayloadAction<boolean>) => {
      state.epg.isActive = action.payload
    },
    appLeftMenu_epg_setIsKeyboardMode: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.epg.isKeyboardMode = action.payload
    },
    appLeftMenu_epg_setItems: (
      state,
      action: PayloadAction<IGetEpgByDayResponse[]>
    ) => {
      state.epg.epgLength = action.payload?.length ?? 0
      state.epg.payloadLength = action.payload?.length ?? 0
      state.epg.items = action.payload
    },
    player_hlsPlayer_currentChannelEpg_unshifItems: (
      state,
      action: PayloadAction<IGetEpgByDayResponse[]>
    ) => {
      state.epg.items.unshift(...action.payload)
      state.epg.epgLength = state.epg.items.length ?? 0
      state.epg.payloadLength = action.payload.length
      if (state.epg.isKeyboardMode) {
        state.epg.activeEpgIdx = state.epg.activeEpgIdx + action.payload.length
      } else {
        state.epg.activeEpgIdx = action.payload.length
      }
    },
    player_hlsPlayer_currentChannelEpg_pushItems: (
      state,
      action: PayloadAction<IGetEpgByDayResponse[]>
    ) => {
      if (!state.epg.isKeyboardMode) {
        state.epg.activeEpgIdx = state.epg.epgLength - 1
      }
      state.epg.items.push(...action.payload)
      state.epg.epgLength = state.epg.items.length ?? 0
      state.epg.payloadLength = action.payload.length
    },
    appLeftMenu_epg_setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.epg.isFetching = action.payload
    },
    appLeftMenu_epg_setIsloading: (state, action: PayloadAction<boolean>) => {
      state.epg.isLoading = action.payload
    },
    appLeftMenu_epg_decrementPreviousDay: (state) => {
      state.epg.previousDay--
      state.epg.epgDay = state.epg.previousDay
    },
    appLeftMenu_epg_incrementNextDay: (state) => {
      state.epg.nextDay++
      state.epg.epgDay = state.epg.nextDay
    },
    appLeftMenu_epg_setActiveEpgIdx: (state, action: PayloadAction<number>) => {
      state.epg.activeEpgIdx = action.payload
    },
    appLeftMenu_epg_decrementActiveEpgIdx: (state) => {
      state.epg.activeEpgIdx--
    },
    appLeftMenu_epg_incrementActiveEpgIdx: (state) => {
      state.epg.activeEpgIdx++
    },
    appLeftMenu_epg_setEpgDataFromPlayback: (
      state,
      action: PayloadAction<ISetEpgDataFromPlayback>
    ) => {
      state.epg.items = action.payload.items
      state.epg.activeEpgIdx = action.payload.activeEpgIdx
      state.epg.previousDay = action.payload.previousDay
      state.epg.epgLength = action.payload.epgLength
      state.epg.payloadLength = action.payload.epgLength
    },
    appLeftMenu_epg_setFetchedChannelData: (
      state,
      action: PayloadAction<{channelId: number, channelIdx: number}>
    ) => {
      state.epg.fetchedChannelId = action.payload.channelId
      state.epg.fetchedChannelIdX = action.payload.channelIdx
    },

    // Detail
    appLeftMenu_detail_reset: (state) => {
      state.detail = initialState.detail
    },
    appLeftMenu_detail_setIsOpened: (state, action: PayloadAction<boolean>) => {
      state.detail.isOpened = action.payload
    },
    appLeftMenu_detail_setFetchedEpgIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.detail.fetchedEpgIdx = action.payload
    },
    appLeftMenu_detail_watchBtn_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.detail.watchBtn.isActive = action.payload
    },
    appLeftMenu_detail_description_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.detail.description.isActive = action.payload
    },

    // Gallery
    appLeftMenu_detail_gallery_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.detail.gallery.isActive = action.payload
    },
    appLeftMenu_detail_gallery_incrementActiveThumbIdx: (state) => {
      state.detail.gallery.activeImageIdx++
    },
    appLeftMenu_detail_gallery_decrementActiveThumbIdx: (state) => {
      state.detail.gallery.activeImageIdx--
    },
    appLeftMenu_detail_gallery_setActiveThumbIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.detail.gallery.activeImageIdx = action.payload
    },
    appLeftMenu_detail_gallery_clickThumb: (
      state,
      action: PayloadAction<number>
    ) => {
      if (state.detail.description.isActive)
        state.detail.description.isActive = false
      if (state.detail.watchBtn.isActive)
        state.detail.watchBtn.isActive = false
      if (!state.detail.gallery.isActive) state.detail.gallery.isActive = true
      state.detail.gallery.activeImageIdx = action.payload
    },
  },
})

export const appLeftMenuApi = createApi({
  reducerPath: 'appLeftMenuApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_REACT_PLAYER_API,
  }),

  endpoints: (builder) => ({
    // https://webapp.hls.tv/api/channels/epg/current-all
    get_all_current_epg: builder.query<
      IAllChannelsCurrentEpgResponse[],
      { playlist_hash: string }
    >({
      queryFn: async (_args) => {
        const url = `${process.env.BASE_REACT_PLAYER_API}/api/channels/epg/current-all`
        try {
          const { data } = await axios.post<IAllChannelsCurrentEpgResponse[]>(
            url,
            _args
          )
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 15,
    }),

    // https://webapp.hls.tv/api/channels/epg/by-days
    get_epg_by_day_left_menu: builder.query<
      IGetEpgByDayResponse[],
      GetEpgByDayRequest
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_REACT_PLAYER_API}/api/channels/epg/by-days`

        try {
          const { data } = await axios.post<IGetEpgByDayResponse[]>(url, _args)

          if (_args.from === -1 && _args.to === 1) {
            // find current program and set active epgIdx
            const timeNow = dayjs().unix()
            data.forEach((program, index) => {
              if (program.start_at <= timeNow && program.stop_at >= timeNow) {
                _api.dispatch(
                  appLeftMenuActions.appLeftMenu_epg_setActiveEpgIdx(index)
                )
              }
            })
            _api.dispatch(appLeftMenuActions.appLeftMenu_epg_setItems(data))
            _api.dispatch(
              appLeftMenuActions.appLeftMenu_epg_setIsloading(false)
            )
          }

          if (_args.from > 0 && _args.to > 0) {
            _api.dispatch(appLeftMenuActions.appLeftMenu_epg_incrementNextDay())
            _api.dispatch(
              appLeftMenuActions.player_hlsPlayer_currentChannelEpg_pushItems(
                data
              )
            )
            _api.dispatch(
              appLeftMenuActions.appLeftMenu_epg_setIsFetching(false)
            )
          }

          if (_args.from < 0 && _args.to < 0) {
            _api.dispatch(
              appLeftMenuActions.appLeftMenu_epg_decrementPreviousDay()
            )
            _api.dispatch(
              appLeftMenuActions.player_hlsPlayer_currentChannelEpg_unshifItems(
                data
              )
            )
            _api.dispatch(
              appLeftMenuActions.appLeftMenu_epg_setIsFetching(false)
            )
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          if (_args.from === -1 && _args.to === 1) {
            _api.dispatch(appLeftMenuActions.appLeftMenu_epg_epgReset())
            _api.dispatch(
              appLeftMenuActions.appLeftMenu_channels_setChannelsIsActive(true)
            )
          }
          _api.dispatch(appLeftMenuActions.appLeftMenu_epg_setIsFetching(false))
          _api.dispatch(appLeftMenuActions.appLeftMenu_epg_setIsloading(false))
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              t('errors:left-menu:get-epg')
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    get_program_details: builder.query<
      IGetProgramDetailsResponse,
      { url: string }
    >({
      queryFn: async (_args, _api) => {
        try {
          const { data } = await axios.get<IGetProgramDetailsResponse>(
            _args.url
          )

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const appLeftMenuReducer = appLeftMenuSlice.reducer
export const appLeftMenuActions = appLeftMenuSlice.actions

export const {
  useGet_all_current_epgQuery,
  useLazyGet_epg_by_day_left_menuQuery,
  useGet_program_detailsQuery,
} = appLeftMenuApi
