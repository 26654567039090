import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ChoosePlan } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'choose-plan'*/ '@components/Overlays/ChoosePlan/ChoosePlan'
    ),
  'ChoosePlan'
)

export const LazyChoosePlan: FC = () => {
  const isOpened = useTypedSelector((state) => state.overlays.choosePlan.isOpened)

  return <Suspense fallback={null}>{isOpened && <ChoosePlan />}</Suspense>
}
