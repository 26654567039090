import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ConfirmUnbind } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'confirm-unbind'*/ '@components/Dialogs/ConfirmUnbind/ConfirmUnbind'
    ),
  'ConfirmUnbind'
)

export const LazyConfirmUnbind: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.unbind.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <ConfirmUnbind />}</Suspense>
}
