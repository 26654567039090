import React, { FC } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { Dialog } from '@components/Dialogs/Dialog'
import { InfoDialogActions } from '@components/Dialogs/Common/InfoDialog/info.dialog.actions'
import { InfoDialogBody } from '@components/Dialogs/Common/InfoDialog/InfoDialogBody'

export const InfoDialog: FC = () => {
  const isOpened = useTypedSelector((state) => state.dialogs.info.isOpened)

  if (!isOpened) return null

  return (
    <>
      <InfoDialogActions />
      <Dialog infoDialog>
        <InfoDialogBody />
      </Dialog>
    </>
  )
}
